import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';

import Footer from '../../Shared/Footer';
import PricingComponent from './PricingComponent';
import CookieConsent from '../../../../../../components/CookieConsent';

const Pricing2 = () => {
    return (
        <>
            <CookieConsent></CookieConsent>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Pricing' />
            <PricingComponent />
            <div style={{ height: "10vh" }}></div>
            <Footer />
        </>
    );
};

export default Pricing2;