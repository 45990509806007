import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import bannerBg4 from '../../../assets/images/banner/stats.png'
import bannerBg5 from '../../../assets/images/banner/banner-bg5.png'
import bannerBg6 from '../../../assets/images/banner/banner-bg6.png'

const HomeTwoBanner = () => {
    return (
        <>
            <section className="sc-banner-section-area sc-banner-section-area2 sc-pb-0">
                <div className="container-fluid p-0 position-relative container-fluid2" > 
                    <Fade bottom delay={200}>
                        <div className="banner-header-content text-center position-relative p-z-idex">
                            <h1 className="sc-mb-25">Welcome to the best Bulk sms service provider.</h1>
                            <div className="description sc-mb-40">
                                Send SMS all over the world.
                            </div>
                            <div className="sc-primary-btn">
                                <a className="secondary-btn2" href="/signup"><span>Try it for Free</span></a>
                            </div>
                        </div>
                    </Fade>

                
                </div>
            </section>
        </>
    );
};

export default HomeTwoBanner;