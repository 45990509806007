import React from 'react';
import Header from '../../Shared/Header';
import Home2Banner from './HomeTwoBanner';
import Works from './Works';
import Feature from '../../Shared/Feature';
import Business from './Business';
import ClientTestimonial from './ClientTestimonial';
import Home2Price from './Home2Price';
import AboutUs from '../../Shared/AboutUs';
import Apps from '../../Shared/Apps';
import logo2 from '../../../assets/images/logo2.png'
import Footer from '../../Shared/Footer';
import NoCodeLanding from '../../Shared/NoCodeLanding';
import FaqQuestion from '../../Shared/FaqQuestion';
import UseYourNumbersLanding from '../../Shared/UseYourNumbersLanding';
import CookieConsent from '../../../../../../components/CookieConsent';
import Banner from '../../../../../../components/Banner';
import { useState } from 'react';
const HomeTwo = () => {

    return (
        <>
            <CookieConsent></CookieConsent>
           
            <Header
                parentMenu='home'
                headerNormalLogo={logo2}
                headerStickyLogo={logo2}
                headerClass='sc-header-section sc-header-section2'
                signUpBtn='secondary-btn'
            />
            <Home2Banner />
            <Works />
            <AboutUs />
            <NoCodeLanding />
            <UseYourNumbersLanding />
            <Feature bgYellow={'yellow-bg'} />
            <Business />
            <ClientTestimonial />
            <FaqQuestion />
            <Apps />
            <Footer />
        </>
    );
};

export default HomeTwo;