import React, { useState } from 'react';
import './Register.css'
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBInput,
  MDBIcon,
  MDBCheckbox
}
  from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import NavBar from '../../components/Navbar';
import {
  Layout,
  Menu,
  Button,
  Typography,
  Card,
  Form,
  Input,
  Checkbox,
} from "antd";
import logo1 from "../user/assets/images/Google__G__Logo.svg.png";


import { Link } from "react-router-dom";
import {
  DribbbleOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const template = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const profile = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const signup = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const signin = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      className="fill-muted"
      d="M12.25,14H1.75A1.752,1.752,0,0,1,0,12.25V3.5A1.752,1.752,0,0,1,1.75,1.75h.876V.875a.875.875,0,0,1,1.75,0V1.75h5.25V.875a.875.875,0,0,1,1.75,0V1.75h.875A1.752,1.752,0,0,1,14,3.5v8.75A1.752,1.752,0,0,1,12.25,14ZM3.5,4.375a.875.875,0,0,0,0,1.75h7a.875.875,0,0,0,0-1.75Z"
    />
  </svg>,
];

function Register() {
  const cookies = new Cookies();


  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatedPassword, setRepeatedPassword] = useState("")
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [terms, setTerms] = useState(false)
  let navigate = useNavigate();

  const routeChange = () => {
    let path = `/login`;
    navigate(path);
  }


  function validateEmail(email) {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.match(emailRegex);
  }


  async function register() {
    if (password == "" || (repeatedPassword != password)) {
      if (password == "")
        alert("Please enter a password")
      else
        alert("Passwords do not match")
      return;
    }
    if (!validateEmail(email)) {
      alert("Please enter a valid email address")
      return;
    }

    if (!terms) {
      alert("Please agree to the terms and conditions before continuing.")
      return;
    }
    console.log("Entering")
    await fetch(global.SERVIP + 'users/register', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then(response => {
        if (response.ok) {
          response.json().then(res => {
            console.log(res)
            if (res.status == 400)
              alert('User alredy exists')
            else if (res.status == 401)
              alert('Verification email has alredy been sent')
            else {
              alert('Success, verification email has been sent to ' + email + ". It may take up to 5 minutes for it to be delivered. Please, also check the spam folder. ");
              routeChange()
            }
          })
        }
        else
          alert('Error')
      })


  }

  useEffect(() => {

    if (cookies.get('token') != null && cookies.get('token').length > 0) {
      navigate('/dashboard')
    }
    if (cookies.get("functionality_cookies") === 'true')
      if (cookies.get('tema') == null) {
        cookies.set('tema', 0, { path: '/', expires: new Date(Date.now() + 365 * 86400 * 1000) })
      }

    if (window.google) {
      if (global.iskoristio) return;
      global.iskoristio = true;
      window.google.accounts.id.initialize({
        client_id: "633428894008-44t6dh44j7bddob5bb0v77lp3eglp2h5.apps.googleusercontent.com",
        callback: (response) => {
          fetch(global.SERVIP + "users/googleSignIn", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({ credential: response.credential }),
          })
            .then(res => res.json())
            .then((data) => {
              if (data?.ok && data?.token) {
                cookies.set('token', data.token, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });

                if (cookies.get("functionality_cookies") === 'true')
                  if (!cookies.get('tema') || cookies.get('tema') == 0)
                    navigate("/api");
                  else
                    navigate("/outreach")
                else {
                  navigate("/")
                }
              }
              else if (data?.message) {
                alert(data.message)
              }
              else {
                alert("Error!")
              }
            })
            .catch(() => {
              alert("Error!")
            });

        }
      })
      window.google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
        theme: "outline",
        text: "continue_with",
        size: 'large',
        shape: "rectangular",
        width: "220px"
      });

    }
  })

  return (
    <div>
      <NavBar />
      <div className="layout-default ant-layout layout-sign-up">

        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content">
              <Title>Sign Up for TextFlow</Title>
              <p className="text-lg">
                Level up your communications
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            bordered="false"
          >
            <div className="sign-up-gateways" style={{ marginTop: 10, display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <div id="signUpDiv"></div>
            </div>
            <p className="text-center my-25 font-semibold text-muted">Or</p>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={() => register()}
              className="row-col"
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email adress!" },
                ]}
              >
                <Input onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input type={passwordVisible ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="repeatedPassword"
                rules={[
                  { required: true, message: password != repeatedPassword ? "Passwords do not match" : "Please repeat your password!" },
                ]}
              >
                <Input type={passwordVisible ? 'text' : 'password'} onChange={(e) => setRepeatedPassword(e.target.value)} placeholder="Repeat password" />
              </Form.Item>
              <Form.Item valuePropName="checked">
                <Checkbox
                  value={terms}
                  onClick={() => { setTerms(!terms) }}
                >
                  I agree the{" "}
                  <Link to='/tos' className="font-bold text-dark">
                    Terms and Conditions
                  </Link>
                </Checkbox>
              </Form.Item>
              <Form.Item >
                <Checkbox
                  onClick={() => { setPasswordVisible(!passwordVisible) }}
                  value={passwordVisible}>
                  Show password
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                //onClick={async()=> await register()}
                >
                  SIGN UP
                </Button>
              </Form.Item>
            </Form>
            <p className="font-semibold text-muted text-center">
              Already have an account?{" "}
              <a href="/login" className="font-bold text-dark">
                Sign In
              </a>
            </p>
          </Card>
        </Content>
        <Footer>
          <Menu mode="horizontal">

            <Menu.Item>
              <a href='https://github.com/Skocimis/textflow.js'>{<GithubOutlined />}</a>
            </Menu.Item>
          </Menu>
          <p className="copyright">
            {" "}
            Copyright © 2023 by <a href="#pablo">TextFlow Ltd </a>.{" "}
          </p>
        </Footer>
      </div>
    </div>
  );
}

export default Register