import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import featureImg7 from '../../../assets/images/feature/feature_img7.png'
import CodeSippetTabs from '../../../../../../components/CodeSnippetTabs'
import {languages} from '../../../../../../data/snippets'

const VerificationService = () => {
    return (
        <>
            <section className="sc-service-section-area sc-pt-210 sc-md-pt-90 sc-pb-140 sc-md-pb-50 sc-pb-30 container-fluid2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="sc-heading-area text-center sc-mb-60">
                                <h2 className="heading-title">Securely Verify User Identities</h2>
                            </div>
                        </div>
                    </div>


            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center" style={{display: 'flex', justifyContent: 'center'}}>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">How does it work?</span>
                                        <h2 className="heading-title sc-mb-25">Just send a request with users number, and we will do the rest.</h2>
                                        <div className="description sc-mb-25">
                                        Fortify User Verification with TextFlow's SMS Verification API: Simplified, Reliable, and Highly Secure. Safeguard your application by effortlessly integrating our API to verify user identities. Rest easy knowing that our service is available around the clock, ensuring uninterrupted verification. Experience seamless implementation, robust security measures, and peace of mind with TextFlow's SMS Verification API.                                        </div>
                                    </div>
                                    <div className="feature_check">
                                    <ul style={{listStyle:'none', padding:0}}>
                                            <li><i className="ri-checkbox-circle-fill"></i>Secure Verification: Ensure top-level security with our SMS Verification API.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Seamless Experience: Simplify verification with a user-friendly API for a hassle-free experience.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Reliable Availability: Count on uninterrupted verification capabilities with our API.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade left>
                        <div className="row align-items-center" style={{backgroundColor: '#101621', borderRadius:10, padding:10}}>
                        <CodeSippetTabs
                languages={languages}
              />
                    </div>
                        </Fade>
                    </div>
                </div>
            </section>
            <div className="btn-area sc-mt-40" style={{display: 'flex', flexDirection: 'column',justifyContent: 'center',alignItems: 'center'}}>
                                        <a className="primary-btn" href="/signup" style={{width: 200}}><span>Get Started</span></a>
                                    </div>

                </div>
            </section>
        </>
    );
};

export default VerificationService;