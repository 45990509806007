import React, { useState } from 'react';
import './CodeSnippetTabs.css';
import CodeSnippet from './CodeSnippet';


const CodeSnippetTabs = ({ languages, codes, light }) => {
  const [activeLanguage, setActiveLanguage] = useState(0);

  return (
    <div className="cont" style={{ textAlign: "left" }}>
      <div className="language-buttons">
        {languages.map((language, index) => (
          <button
            key={index}
            onClick={() => setActiveLanguage(index)}
            disabled={activeLanguage === index}
            className="language-button"
          >
            {language.name}
          </button>
        ))}
      </div>
      <div style={{color:'white'}}>
        Browse the <a  href={languages[activeLanguage].docsUrl} target="_blank">Documentation</a> or check out our <a  href={languages[activeLanguage].githubUrl} target="_blank">Github page</a> for {languages[activeLanguage].name}
      </div>
      {(typeof languages[activeLanguage].code === "string") ? <div style={{ fontSize: "0.9rem" }}>
        <div style={{color:'white'}}>Installation: </div>
        {
          languages[activeLanguage].installation && <CodeSnippet
            language={languages[activeLanguage].installation.language}
            code={languages[activeLanguage].installation.code}
          >
          </CodeSnippet>
        }

        <br />
        <div style={{color:'white'}}>Code: </div>
        {
          <CodeSnippet
            language={languages[activeLanguage].id}
            code={languages[activeLanguage].code}
            light={false}
          />
        }

      </div> : <div style={{ fontSize: "0.9rem" }}>
        {languages[activeLanguage].code.map((e, index) =>
          <div key={index}>
            <div>{e.name}</div>
            <CodeSnippet
              language={e.language}
              code={e.code}
              light={false}
            />
          </div>
        )}
      </div>}



    </div>
  );
};

export default CodeSnippetTabs;
