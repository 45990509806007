import React, { useRef, useEffect } from 'react';
import './CodeSnippet.css';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import java from 'react-syntax-highlighter/dist/esm/languages/hljs/java';
import cs from 'react-syntax-highlighter/dist/esm/languages/hljs/csharp';
import php from 'react-syntax-highlighter/dist/esm/languages/hljs/php';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/solarized-dark';

SyntaxHighlighter.registerLanguage('js', js);
SyntaxHighlighter.registerLanguage('java', java);
SyntaxHighlighter.registerLanguage('cs', cs);
SyntaxHighlighter.registerLanguage('php', php);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('xml', xml);

const CodeSnippet = ({ language, code, light }) => {
  const codeRef = useRef(null);
  const roundedStyle = {
    borderRadius: 10,
  };
  return (
    <div style={{ width: "inherit"}}>
      <SyntaxHighlighter  language={language} style={{ ...style, ...roundedStyle }} PreTag="div" children={code} />
    </div>
  );
};

export default CodeSnippet;
