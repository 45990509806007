import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import NavBar from '../../components/Navbar';
import './Pricing.css'
import Footer from "../user/components/layout/Footer";

import {
  Row,
  Col,
  Card,
  Statistic,
  Button,
  List,
  Descriptions,
  Avatar,
  Typography,

} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Cookies from 'universal-cookie';
//fetch pacage levels to get max messages

// when clicked on max messages show all pacages that match that pacage level

function Pricing(props) {

  const cookies = new Cookies();


  const [countries, setCountries] = useState([]);
  const [curPacages, setCurPacages] = useState([]);
  const [curRegion, setCurRegion] = useState('USA Canada');
  let navigate = useNavigate();

  const routeChange = () => {
    let path = `/signup`;
    navigate(path);
  }

  useEffect(() => {
    if (cookies.get('token') != null && cookies.get('token').length > 0) {
      navigate('/dashboard')
    }

    const requestCountries = async () => {
      console.log(global.SERVIP)
      await fetch(global.SERVIP + 'countries', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              console.log(res)
              setCountries(res)
              setCurRegion(res[0].name)
              getPacagesFromCounty(res[0])
            })
          }
          else
            alert('Error')
        })
    }
    requestCountries()
  }, []);

  async function getPacagesFromCounty(item) {
    setCurRegion(item.name)
    console.log(global.SERVIP)
    await fetch(global.SERVIP + 'countries/' + item.id + '/packages', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(res => {
            setCurPacages(res);
            console.log(curPacages)
          })
        }
        else
          alert('Error')
      })
  }


  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <NavBar />
      <div className="container">
        <Col span={24} md={16} className="mb-24">
          <Card
            className="header-solid h-full"
            bordered={false}
            title={[<h3 class="font-semibold m-0" >Purchase packages</h3>]}
            bodyStyle={{ paddingTop: "0" }}
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <h3 class="ant-list-item-meta-title">For custom packages, contact sales: sales@textflow.me</h3>
            <h3 class="ant-list-item-meta-title">Available regions</h3>
            <Row style={{ marginBottom: 10, display: 'flex', justifyContent: 'center', padding: 10 }}>
              {countries.map((country, index) =>
                <button type="button" class="ant-btn css-dev-only-do-not-override-1n7nwfa"
                  onClick={async () => await getPacagesFromCounty(country)}
                  style={{ margin: 10, color: 'white', backgroundColor: country.name == curRegion ? "black" : "", }}>
                  <h4 style={{ color: country.name == curRegion ? "white" : '#6A76EC' }} class="ant-list-item-meta-title">{country.name}</h4>
                </button>
              )}
            </Row>
            <Row gutter={[24, 24]}>
              {curPacages.map((item, index) => (
                <Col span={24} key={index}>
                  <Card className="card-billing-info" bordered="false">
                    <div className="col-info">
                      <Descriptions title={item.messageLimit + ' messages'}>
                        <Descriptions.Item label="Price " span={3}>
                          {Math.round(item.messageLimit * item.pricePerMessageUSD)} $
                        </Descriptions.Item>
                        <Descriptions.Item label="Price per message" span={3}>
                          {item.pricePerMessageUSD + ' $ per message'}
                        </Descriptions.Item>

                      </Descriptions>
                    </div>
                    <div className="col-action">
                      <button
                        onClick={() => routeChange()}
                        class="ant-btn css-dev-only-do-not-override-1n7nwfa ant-btn-primary"
                        style={{ margin: 10, backgroundColor: "#6A76EC" }}>
                        <h4 style={{ color: 'white', }} class="ant-list-item-meta-title">Purchase</h4>
                      </button>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </div>
      <Footer></Footer>

    </div>

  )
}

export default Pricing