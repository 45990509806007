import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { useState, useEffect } from 'react';
import {
    Layout,
    Menu,
    Button,
    Row,
    Col,
    Typography,
    Form,
    Input,
    Switch,
    Checkbox
  } from "antd";

const LoginForm = () => {
    const cookies = new Cookies();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordVisible, setPasswordVisible] = useState(false)
    const navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault()
        event.target.reset()
        toast("Login Successfully")

        setTimeout(() => {
            navigate('/#');
        }, 1500);
    }
 const routeChange = () => {
    let path = `/dashboard`;
    navigate(path);
  }
  async function login() {
    await fetch(global.SERVIP + 'users/login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then(response => {
        if (response.ok) {
          response.json().then(res => {
            console.log(res);
            cookies.set('token', res.token, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
            routeChange()

          })
        }
        else
          alert('Wrong credentials')
      })
  }

  useEffect(() => {

    if (cookies.get('token') != null && cookies.get('token').length > 0) {
      navigate('/dashboard')
    }
    if (window.google) {
      if (global.iskoristio) return;
      global.iskoristio = true;
      window.google.accounts.id.initialize({
        client_id: "633428894008-44t6dh44j7bddob5bb0v77lp3eglp2h5.apps.googleusercontent.com",
        callback: (response) => {
          fetch(global.SERVIP + "users/googleSignIn", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({ credential: response.credential }),
          })
            .then(res => res.json())
            .then((data) => {
              if (data?.ok && data?.token) {
                cookies.set('token', data.token, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
                navigate("/dashboard");
              }
              else if (data?.message) {
                alert(data.message)
              }
              else {
                alert("Error!")
              }
            })
            .catch(() => {
              alert("Error!")
            });

        }
      })
      window.google.accounts.id.renderButton(document.getElementById("divGbox"), {
        theme: "filled_black",
        text: "continue_with",
        shape: "rectangular",
        width: "220px"
      });

    }
  },[])

    return (
        <>
            <div className="sc-login-section sc-pt-120 sc-md-pb-70">
                <div className="sc-login-box" >
                    <div id = "divGbox" style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center",margin:10}}></div>
                        <div className="login-form">
                            <label className="login-label">Email address</label>
                            <input onChange={(e)=>setEmail(e.target.value)} className="from-control" type="email" id="form-email" name="email" placeholder="Enter Your Email" required autoComplete="off" />
                            <label className="login-label">Password</label>
                            <input  onChange={(e)=>setPassword(e.target.value)} className="from-control" type="password" id="password" name="password" placeholder="Enter Your Password" autoComplete="off" required />
                        </div>
                        <div className="check-box d-flex justify-content-between">
                            <div className="input-box"><input type="checkbox" id="Check" name="Check" /><label> Remember Me</label></div>
                            <div className="login-password">
                            <p className="font-semibold text-muted">
                  Forgot password?{" "}
                  <Link to="/restartpassword" className="text-dark font-bold">
                    Get a new one!
                  </Link>
                </p>
                            </div>
                        </div>
                        <div className="submit-btn text-center" onClick={()=>login()}>
                            <input type="submit" value="Login" />
                        </div>
                    <h4 className="sc-text text-center">Don't have an account yet? <a className='primary-color' href="/signup#">Sign Up</a></h4>

                 
                </div>
            </div>
        </>
    );
};

export default LoginForm;