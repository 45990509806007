import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import { useNavigate } from "react-router-dom";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  let navigate = useNavigate();

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
          style={{borderRadius:10}}
        >
          <div className="cta-slogan" style={{color:'white'}}>
            <h3 className="m-0" style={{color: 'white'}}>
              Ready to start ?
              </h3>
              Try it out for free, no credit card required.
          </div>
          <div className="cta-action" style={{display:'flex', alignItems:"center", justifyContent:'center'}} >
            <button
            onClick={() =>{navigate('/signup')}} 
            id="newsletter" style={{borderRadius:10, display:'flex', flexDirection: 'row',justifyContent: 'space-between',alignItems: 'center'}} type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your best email">
             <a style={{marginRight:10}}>Register</a>
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;