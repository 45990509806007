import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);
  let navigate = useNavigate();
  const cookies = new Cookies();

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }
  function navigateWithData() {
    if (cookies.get("functionality_cookies") === 'true')
      cookies.set('tema', 1, { path: '/', expires: new Date(Date.now() + 365 * 86400 * 1000) })
    navigate('/no-code')
  }
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" >
              Welcome to <span className="text-color-primary" style={{ fontWeight: 'bold' }} >TextFlow API</span>
            </h1>
            <div className="container-xm">
              <h2 className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400" style={{ fontSize: "1em" }}>
                A text message API solution to verify and keep your users, or manage your marketing campaigns and customer outreach without coding.
              </h2>
              <div className="reveal-from-bottom" data-reveal-delay="600" style={{ marginTop: '1em', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: 40, alignItems: 'center' }}>
                <button tag="a" color="primary"
                  onClick={() => navigateWithData()}
                  style={{ marginTop: 20, marginBottom: 80, color: 'white', backgroundColor: '#6163FF', fontSize: 15, maxWidth: 600, fontFamily: 'Raleway' }}
                >
                  Switch to no-code, marketing section</button>
                <span style={{ fontWeight: 'bold' }}> Developer section</span>
                <ButtonGroup>
                  <Button onClick={() => { navigate("/signup") }} tag="a" color="primary" style={{ width: 250 }} >
                    Get started
                  </Button>
                  <Button tag="a" color="dark" href="https://docs.textflow.me" style={{ width: 250 }}>
                    View docs
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>

          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;