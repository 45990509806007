import { React, useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-bootstrap/Modal';
import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Input,
} from "antd";
import {
  SearchOutlined,
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  MessageFilled,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Main from "../components/layout/Main";
import Cookies from 'universal-cookie';
import CodeSnippetTabs from "../../../components/CodeSnippetTabs";
import { useNavigate, useLocation } from "react-router-dom";
import { languages } from "../../../data/snippets";
import ava5 from "../assets/images/logo-jira.svg";
import ImportSpreadSheetButton from "../components/ImportSpreadSheetButton";
import "../assets/styles/outreach.css";
import TextArea from "antd/es/input/TextArea";
import CookieConsent from "../../../components/CookieConsent";



const faq = [
  { question: "How does this work", answer: "Input your custumers phone numbers with international prefix and a message you want to send them. After you do that and run the campaign, we will send them that message in the provided interval." },
  { question: "Do SMS notifications engage users more", answer: "It has been proven that SMS engagement is one of the most effective forms of user engagement." },
  { question: "Do i need to put prefix for a number?", answer: "Yes. Example US number: +11234567890" },
  { question: "If I have a package that is cheaper then pay as you go, will it be used?", answer: "Yes, when each message is sent, we will first see if there is a package that you have that is less expensive than pay as you go price, and if it exists we will use that package. ", },
  { question: "Do you offer special pricing for volumes", answer: "Yes, if you plan on larger usage than the packages we are offering provide, contact the sales department sales@textflow.me and someone will reach out shortly." },
]

const pricing = {
  total_price: 0,
  credits: 0,
  payg_price: 0
};

const skroldubina = { value: 0 }
const jobinterval = { value: false };

function Outreach() {
  const { Title, Text } = Typography;
  const [show, setShow] = useState(false);
  const cookies = new Cookies();
  const token = cookies.get('token');
  const [campaigns, setCampaigns] = useState([])
  const [groups, setGroups] = useState([]);
  const [currentGroupShown, setCurrentGroupShown] = useState({});
  const [currentGroup, setCurrentGroup] = useState((cookies.get("functionality_cookies") === 'true') ? (cookies.get('currentGroup') || 0) : 0);
  const [newCampaignMessage, setNewCampaignMessage] = useState((cookies.get("functionality_cookies") === 'true') ? (cookies.get('newCampaignMessage') || "") : "")//
  const [newCampaignDuration, setNewCampaignDuration] = useState((cookies.get("functionality_cookies") === 'true') ? (cookies.get('newCampaignDuration') || 1) : 1);
  const [newCampaignName, setNewCampaignName] = useState((cookies.get("functionality_cookies") === 'true') ? (cookies.get('newCampaignName') || "") : "")
  const [totalCost, setTotalCost] = useState(0);
  const navigate = useNavigate()
  let resetScroll = () => {
    let val = skroldubina.value;
    if (val)
      window.scrollTo(0, val);
  };
  useEffect(resetScroll, [show, groups, currentGroupShown, currentGroup, newCampaignMessage, newCampaignDuration, newCampaignName, totalCost]);




  async function fetchJobs() {
    for (let j = 0; j < campaigns.length; j++) {
      if (campaigns[j].status === 0) {
        fetch(global.SERVIP + 'get-job', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({ job_id: campaigns[j].id })
        })
          .then(response => response.json())
          .then(response => {
            if (response.ok) {
              for (let i = 0; i < campaigns.length; i++) {
                if (campaigns[i].id == campaigns[j].id) {
                  campaigns[i] = createCampaignFromJob(response.job);
                  skroldubina.value = 0;
                  setCampaigns([...campaigns]);
                }
              }
            }
          })
          .catch(e => {
          })
      }
    }
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchJobs();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [campaigns, groups]);


  useEffect(() => {
    if (!token || token.length == 0) {
      navigate('/signup')
      return
    }

    async function requestPacages() {
      if (global.pacages) {
        return;
      }
      global.pacages = [];
      await fetch(global.SERVIP + 'user/pacages', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              global.pacages = res;
              calculateTotalPrice({ o_packages: res });
            })
          }
          else {
            alert('Error P')
          }
        })
    }
    requestPacages();
    async function requestGroups() {
      if (global.marketinggroups) {
        setGroups(global.marketinggroups);
        return;
      }
      global.marketinggroups = [];
      fetch(global.SERVIP + 'get-groups', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
        .then(response => response.json())
        .then(response => {
          if (response.ok) {
            skroldubina.value = window.scrollY;
            global.marketinggroups = response.groups;
            setGroups(global.marketinggroups)
            let o_group = global.marketinggroups ? global.marketinggroups[currentGroup] : null;
            calculateTotalPrice({ o_group });
          }
        })
        .catch(_ => { })
    }
    requestGroups();


    async function requestJobs() {
      if (global.marketingjobs) {
        setCampaigns(global.marketingjobs);
        return;
      }
      global.marketingjobs = [];
      fetch(global.SERVIP + 'get-jobs', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
        .then(response => response.json())
        .then(response => {
          if (response.ok) {
            skroldubina.value = 0;
            global.marketingjobs = response.jobs.map(createCampaignFromJob).reverse();
            setCampaigns(global.marketingjobs);
          }
        })
        .catch(_ => { })
    }
    requestJobs();


    async function requestRemainingCredits() {
      if (global.creditsremaining || global.creditsremaining === 0) {
        pricing.credits = global.creditsremaining;
        return;
      }
      global.creditsremaining = 0;
      fetch(global.SERVIP + 'user/get-credits', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
        .then(response => response.json())
        .then(response => {
          if (response.ok) {
            global.creditsremaining = response.credits
            pricing.credits = global.creditsremaining;
            calculateTotalPrice({});
          }
        })
        .catch(_ => { })
    }
    requestRemainingCredits();


  }, []);

  const createCampaignFromJob = (job) => {
    job.recipients = job.phone_numbers.length;
    job.progress_percent = Math.round(job.progress / job.recipients * 100);
    job.duration = Math.round(job.recipients * job.interval / 3600 / 1000);
    return job
  }
  const continueJob = (id) => {
    fetch(global.SERVIP + 'continue-job', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ job_id: id })
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          for (let i = 0; i < campaigns.length; i++) {
            if (campaigns[i].id == id) {
              campaigns[i] = createCampaignFromJob(response.job);
              skroldubina.value = 0;
              setCampaigns([...campaigns]);
            }
          }
        }
      })
      .catch(e => {
      })
  }
  const pauseJob = (id) => {
    fetch(global.SERVIP + 'pause-job', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ job_id: id })
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          for (let i = 0; i < campaigns.length; i++) {
            if (campaigns[i].id == id) {
              campaigns[i] = createCampaignFromJob(response.job);
              skroldubina.value = 0;
              setCampaigns([...campaigns]);
            }
          }
        }
      })
      .catch(e => {
      })
  }
  const stopJob = (id) => {
    fetch(global.SERVIP + 'stop-job', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ job_id: id })
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          for (let i = 0; i < campaigns.length; i++) {
            if (campaigns[i].id == id) {
              campaigns[i] = createCampaignFromJob(response.job);
              skroldubina.value = 0;
              setCampaigns([...campaigns]);
            }
          }
        }
      })
      .catch(e => {
      })
  }
  const handleClose = () => {
    skroldubina.value = window.scrollY;
    setShow(false)
  };

  const closeAndSave = async () => {
    if (currentGroupShown.nova) {
      let cg = await createGroup({ phone_numbers: currentGroupShown.phone_numbers, name: currentGroupShown.name });
      if (cg.ok) {
        handleClose();
        let gl = groups.length;
        let new_groups = [...groups, cg.group];
        skroldubina.value = window.scrollY;
        setGroups(new_groups)
        if (cookies.get("functionality_cookies") === 'true')
          cookies.set('currentGroup', gl, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
        setCurrentGroup(gl);
        calculateTotalPrice({ o_group: new_groups ? new_groups[gl] : null });
      }
      else
        alert(cg.message);
    }
    else {
      let ug = await updateGroup({ phone_numbers: currentGroupShown.phone_numbers, name: currentGroupShown.name, id: currentGroupShown.id })
      if (ug.ok) {
        handleClose();
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].id == ug.group.id) {
            groups[i] = ug.group;
            let new_groups = [...groups];
            skroldubina.value = window.scrollY;
            setGroups(new_groups)
            if (cookies.get("functionality_cookies") === 'true')
              cookies.set('currentGroup', i, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
            setCurrentGroup(i);
            calculateTotalPrice({ o_group: new_groups ? new_groups[i] : null });
            return;
          }
        }
      }
      else
        alert(ug.message);
    }
  }

  function addNumber() {
    let obj = { phone_numbers: currentGroupShown.phone_numbers, name: currentGroupShown.name, nova: currentGroupShown.nova, id: currentGroupShown.id }
    obj.phone_numbers.push("")
    skroldubina.value = window.scrollY;
    setCurrentGroupShown(obj)
  }
  function updateNumber(value, index) {
    let obj = { phone_numbers: currentGroupShown.phone_numbers, name: currentGroupShown.name, nova: currentGroupShown.nova, id: currentGroupShown.id }
    obj.phone_numbers[index] = value;
    skroldubina.value = window.scrollY;
    setCurrentGroupShown(obj);
  }
  function deleteNumber(index) {
    let obj = { phone_numbers: currentGroupShown.phone_numbers, name: currentGroupShown.name, nova: currentGroupShown.nova, id: currentGroupShown.id }
    obj.phone_numbers.splice(index, 1);
    skroldubina.value = window.scrollY;
    setCurrentGroupShown(obj);
  }
  function setCurrentGroupName(name) {
    let obj = { phone_numbers: currentGroupShown.phone_numbers, name: currentGroupShown.name, nova: currentGroupShown.nova, id: currentGroupShown.id }
    obj.name = name;
    skroldubina.value = window.scrollY;
    setCurrentGroupShown(obj);
  }

  function containsNonGSM7BitCharacters(s) {
    const GSM_7BIT_CHARS = "@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1bÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";
    const EXTENDED_GSM_7BIT_CHARS = "\f^{}\\[~]|€";

    for (let i = 0; i < s.length; i++) {
      if (GSM_7BIT_CHARS.indexOf(s[i]) === -1) {
        if (EXTENDED_GSM_7BIT_CHARS.indexOf(s[i]) === -1 || (i > 0 && s[i - 1] !== '\x1b')) {
          return true;
        }
      }
    }
    return false;
  }

  function smsLengthConsideringExtensions(s) {
    const EXTENDED_GSM_7BIT_CHARS = "\f^{}\\[~]|€";
    let count = 0;

    for (let i = 0; i < s.length; i++) {
      if (EXTENDED_GSM_7BIT_CHARS.indexOf(s[i]) !== -1 && (i === 0 || (i > 0 && s[i - 1] === '\x1b'))) {
        count += 2;
      } else {
        count += 1;
      }
    }
    return count;
  }

  function smsCount(p) {
    if (!p || typeof p !== 'string' || p.length > 1530 || p.length < 1) return 0;

    if (containsNonGSM7BitCharacters(p)) {
      if (p.length > 670) return 0;
      if (p.length <= 70)
        return 1;
      return Math.ceil(p.length / 67);
    } else {
      const effectiveLength = smsLengthConsideringExtensions(p);
      if (effectiveLength <= 160)
        return 1;
      return Math.ceil(effectiveLength / 153);
    }
  }
  function createGroup(group) {
    return new Promise(resolve => {
      if (group.name == "")
        group.name = "New group";
      fetch(global.SERVIP + 'create-group', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ name: group.name, phone_numbers: group.phone_numbers })
      })
        .then(response => response.json())
        .then(response => {
          if (response.ok) {
            resolve({ ok: true, group: response.group })
          }
          else {
            resolve({ ok: false, message: response.message })
          }
        })
    })

  }
  async function updateGroup(group) {
    return new Promise(resolve => {
      if (group.name == "")
        group.name = "New group";
      fetch(global.SERVIP + 'update-group', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ name: group.name, phone_numbers: group.phone_numbers, group_id: group.id })
      })
        .then(response => response.json())
        .then(response => {
          if (response.ok) {
            resolve({ ok: true, group: response.group })
          }
          else {
            resolve({ ok: false, message: response.message })
          }
        })
    })
  }
  function deleteGroup() {
    fetch(global.SERVIP + 'delete-group', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ group_id: currentGroupShown.id })
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          for (let i = 0; i < groups.length; i++) {
            if (groups[i].id == currentGroupShown.id) {
              groups.splice(i, 1);
              let new_groups = [...groups];
              skroldubina.value = window.scrollY;
              setGroups(new_groups)
              if (i == currentGroup) {
                if (cookies.get("functionality_cookies") === 'true')
                  cookies.set('currentGroup', - 1, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
                setCurrentGroup(-1);
                calculateTotalPrice({ o_group: null });
              }
              else if (i < currentGroup) {
                if (cookies.get("functionality_cookies") === 'true')
                  cookies.set('currentGroup', currentGroup - 1, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
                setCurrentGroup(currentGroup - 1);
                calculateTotalPrice({ o_group: new_groups ? new_groups[currentGroup - 1] : null });
              }
              handleClose();
              return;
            }
          }
        }
        else
          alert(response.message);
      })
  }

  async function createCampaign() {
    if (!newCampaignMessage) return alert("Please enter the message text");
    if (newCampaignDuration < 1 || newCampaignDuration > 24 * 30) return alert("Please enter valid duration (1h - 30 days)");
    if (!groups || !groups[currentGroup] || (!groups[currentGroup].id && groups[currentGroup].id !== 0))
      return alert("Please select the group or create it if you haven't already.");
    if (!groups[currentGroup]?.phone_numbers?.length)
      return alert(`Please add at least one phone number to the group that you have selected (${groups[currentGroup]?.name}).`);
    let name = newCampaignName;
    if (!name) name = "New campaign";

    if (pricing.payg_price > pricing.credits) {
      if (!window.confirm("You do not have enough credits to finish the campaign. It will be paused when you run out of credits. You can continue it later when you have more credits. ")) {
        return
      }
    }

    let interval = 3600 * 1000 * newCampaignDuration / groups[currentGroup].phone_numbers.length;//Sada je u minutima, za testiranje
    await fetch(global.SERVIP + 'create-job', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ name: name, interval: interval, group_id: groups[currentGroup].id, message_text: newCampaignMessage })
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          skroldubina.value = 0;
          setNewCampaignDuration(1);
          setNewCampaignName("");
          setNewCampaignMessage("");
          calculateTotalPrice({ o_sms_count: 0, kreiro: true });
          setCampaigns([createCampaignFromJob(response.job), ...campaigns])
          if (cookies.get("functionality_cookies") === 'true') {
            cookies.set('newCampaignName', "", { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
            cookies.set('newCampaignMessage', "", { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
            cookies.set('newCampaignDuration', "", { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
          }
        }
        else
          alert(response.message);
      })
  }

  function openModal(group) {
    skroldubina.value = window.scrollY;
    setCurrentGroupShown(group);
    setShow(true)
  }
  function calculateTotalPrice({ o_group, o_packages, o_sms_count, kreiro }) {
    let group = o_group || (groups ? groups[currentGroup] : null);
    let packages = o_packages || [...(global.pacages || [])];
    let sms_count = o_sms_count || smsCount(newCampaignMessage);
    if (!group || !packages) {
      return;
    }
    pricing.payg_price = 0;
    let sum = 0
    for (let i = 0; i < group.pricing.length; i++) {
      let p = group.pricing[i];
      let totalCount = p.count * sms_count;

      let countryPackageIds = Array.from(packages.keys()).filter(e => packages[e].countries.map(e => e.name).includes(p.country))
      let continentPackageIds = Array.from(packages.keys()).filter(e => packages[e].countries.map(e => e.name).includes(p.continent))
      let worldPackageIds = Array.from(packages.keys()).filter(e => packages[e].countries.map(e => e.name).includes("World"))

      let allPackageIds = [...countryPackageIds, ...continentPackageIds, ...worldPackageIds].sort((a, b) => (packages[a].pricePerMessageUSD - packages[b].pricePerMessageUSD))

      for (let counter = 0; counter < allPackageIds.length; counter++) {
        let pacId = allPackageIds[counter];
        if ((packages[pacId].pricePerMessageUSD >= p.sms_price) && (pricing.credits - pricing.payg_price >= totalCount * p.sms_price)) break;
        if (totalCount <= 0) break;
        let dc = Math.min(packages[pacId].messagesLeft, totalCount);
        packages[pacId].messagesLeft -= dc;
        sum += dc * packages[pacId].pricePerMessageUSD;
        totalCount -= dc;
      }
      if (totalCount <= 0) continue;
      sum += totalCount * p.sms_price;
      pricing.payg_price += totalCount * p.sms_price;
    }
    pricing.total_price = sum;
    skroldubina.value = kreiro ? 0 : skroldubina.value;
    setTotalCost(pricing.total_price.toFixed(5))
  }
  return (
    <>
      <Main>
        <CookieConsent></CookieConsent>
        <div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Input placeholder="Group name" value={currentGroupShown.name} onChange={(e) => {
                skroldubina.value = window.scrollY;
                setCurrentGroupName(e.target.value)
              }} style={{ marginRight: 10 }} />
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', flexDirection: 'column', textAlign: "center" }}>
              <div style={{ marginTop: 10 }}>
                <ImportSpreadSheetButton callback={(result) => {
                  let obj = { phone_numbers: currentGroupShown.phone_numbers, name: currentGroupShown.name, nova: currentGroupShown.nova, id: currentGroupShown.id }
                  for (let i = 0; i < result.length; i++) {
                    obj.phone_numbers.push(result[i])
                  }
                  skroldubina.value = window.scrollY;
                  setCurrentGroupShown(obj)
                }} component={<Button variant="secondary" onClick={() => { }} style={{ overflowWrap: "break-word" }}>Import from spreadsheet</Button>}></ImportSpreadSheetButton>
                <div style={{ fontSize: "small", fontWeight: "bold" }}>(CSV, XLS, XLSX, TXT)</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 200, overflow: "auto", marginTop: 10, width: '100%', }}>
                {currentGroupShown.phone_numbers && currentGroupShown.phone_numbers.map((number, index) =>
                  <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                    <Input
                      placeholder="Number with international prefix"
                      onChange={(e) => updateNumber(e.target.value, index)}
                      style={{ marginRight: 10 }} value={number} />
                    <div style={{
                      WebkitTouchCallout: "none",
                      WebkitUserSelect: "none",
                      KhtmlUserSelect: "none",
                      MozUserSelect: "none",
                      msUserSelect: "none",
                      userSelect: "none",
                      cursor: "pointer",
                      padding: 4,
                      borderRadius: 20
                    }} onClick={() => { deleteNumber(index) }}>❌</div>
                  </div>)}
              </div>
              <Button variant="secondary" onClick={() => addNumber()} style={{ margin: 10 }}>
                Add number manually
              </Button>
              <a>{currentGroupShown?.phone_numbers?.length} numbers</a>
            </Modal.Body>
            <Modal.Footer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div>
                <Button variant="secondary" onClick={deleteGroup} style={{ background: "#ff4136", color: "white" }}>
                  Delete group
                </Button>
              </div>
              <div>
                <Button variant="secondary" onClick={handleClose} style={{ marginRight: 10 }}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={closeAndSave}>
                  {currentGroupShown.nova ? "Create new group" : "Save and select"}
                </Button>
              </div>

            </Modal.Footer>
          </Modal>
          <Row gutter={[24, 0]}>

            <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">{/* */}
              <Card className="criclebox cardbody h-full" style={{ fontSize: 20 }}>
                <div className="project-ant">
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Title >Start a new campaign</Title>
                    <Input onChange={(e) => {
                      skroldubina.value = window.scrollY;
                      if (cookies.get("functionality_cookies") === 'true')
                        cookies.set('newCampaignName', e.target.value, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
                      setNewCampaignName(e.target.value)
                    }} placeholder="Give campaign a name"></Input>
                    <div className="ant-list-box table-responsive" style={{ width: '100%', borderWidth: "1px", borderColor: "lightgray", padding: 10, borderStyle: "solid", marginTop: 10, borderRadius: 10 }}>
                      <Title level={4} style={{ marginBottom: 10, textAlign: "center" }}>Choose one of your groups or create a new one</Title>

                      <div style={{ maxHeight: 200, overflow: 'auto' }}>

                        <table style={{ tableLayout: "fixed" }}>
                          <thead style={{ position: "sticky", top: 0, zIndex: 1, background: "white" }}>
                            <tr>
                              <th style={{ textAlign: "center", paddingLeft: 0, paddingRight: 0 }}>Name</th>
                              <th style={{ textAlign: "center", paddingLeft: 0, paddingRight: 0 }}>Num. of recipients</th>
                            </tr>
                          </thead>
                          <tbody>
                            {groups.map((group, index) => (
                              <tr key={group.name + index}
                                onClick={() => openModal(group, index)}
                                style={{ backgroundColor: (index == currentGroup) ? "#f8f9ff" : "white" }}
                              >
                                <td style={{ textAlign: "center", paddingLeft: 0, paddingRight: 0, paddingBottom: 2, paddingTop: 4, overflowWrap: "break-word" }}>
                                  <h6 style={{ overflowWrap: "break-word" }}>
                                    {group.name}
                                  </h6>
                                </td>
                                <td style={{ textAlign: "center", paddingLeft: 0, paddingRight: 0, paddingBottom: 2, paddingTop: 4 }}>
                                  <span className="text-xs font-weight-bold">
                                    {group.phone_numbers ? group.phone_numbers.length : 0}
                                  </span>

                                </td>


                              </tr>
                            ))}
                            {(groups.length == 0) ? <tr>
                              <td colSpan={2} style={{ textAlign: "center", fontSize: "large", overflowWrap: "anywhere" }}>You don't have any groups (create a new one)</td>
                            </tr> : null}
                          </tbody>
                        </table>
                      </div>
                      <Button style={{ width: '100%', marginTop: 10 }} onClick={() => openModal({ phone_numbers: [], name: "", nova: true })}>Create a new recipient group</Button>
                      <hr></hr>
                      {groups[currentGroup] && <Title style={{ textAlign: "center", marginTop: 5 }} level={3}>Chosen group: {groups[currentGroup].name} ({groups[currentGroup].phone_numbers && groups[currentGroup].phone_numbers.length} recipients)</Title>}
                    </div>
                  </div>

                </div>
                <div className="uploadfile shadow-none" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Title style={{ marginTop: 30 }} level={5} >
                    Write the message and set the campaign duration
                  </Title>
                  <TextArea
                    placeholder="Your SMS text here"
                    style={{ margin: 20, fontSize: 15, width: '90%' }}
                    onChange={(e) => {
                      skroldubina.value = window.scrollY;
                      if (cookies.get("functionality_cookies") === 'true')
                        cookies.set('newCampaignMessage', e.target.value, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
                      setNewCampaignMessage(e.target.value)
                      calculateTotalPrice({ o_sms_count: smsCount(e.target.value) });
                    }}
                    value={newCampaignMessage}
                  >
                  </TextArea>
                  <Title level={5}>Duration of your campaign in hours. We will send messages systematically during that period. </Title>
                  <Input
                    onChange={(e) => {
                      skroldubina.value = window.scrollY;
                      if (cookies.get("functionality_cookies") === 'true')
                        cookies.set('newCampaignDuration', e.target.value, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });
                      setNewCampaignDuration(e.target.value)
                    }}
                    value={newCampaignDuration}
                    type="number"
                    min={1}
                    max={24 * 30}
                    style={{ margin: 20, fontSize: 15, width: '90%', marginTop: 0, height: 50 }}
                  />
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: "center" }}>
                    <div style={{ marginTop: 10 }}>Total cost: {totalCost}$</div>
                    {pricing.payg_price > pricing.credits || pricing.total_price == 0 || <p>After this campaign, your remaining balance will be {(pricing.credits - totalCost).toFixed(5)}$.{(pricing.payg_price != pricing.total_price) ? " Packages will also be used for situations where they are cheaper." : null} Final price may differ if you run multiple campaigns concurrently. </p>}
                    {(pricing.payg_price <= pricing.credits) || <div style={{ fontSize: "medium" }} id={"not-enough-credits-div"}>
                      You do not have enough credits for this campaign.
                      You need another ${Math.ceil((pricing.payg_price - pricing.credits) * 100) / 100} of credits to start this campaign. <a style={{ color: 'blue' }} href="#" onClick={(e) => {
                        e.preventDefault();
                        let creditsn = parseInt(Math.max(Math.ceil((pricing.payg_price - pricing.credits) * 100), 200));
                        fetch(global.SERVIP + 'user/credits/pay', {
                          method: 'POST',
                          headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                          },
                          body: JSON.stringify({ amount: creditsn, outreach: true })
                        })
                          .then(response => response.json())
                          .then(response => {
                            if (response.ok)
                              window.location.assign(response.url);
                          })
                          .catch(e => {
                            console.log(e);
                          })
                      }}>Buy credits</a> to proceed. {(pricing.payg_price - pricing.credits < 2) ? " The minimum amount of credits to buy is 2$. " : null}
                    </div>}
                    <Button style={{ width: '90%' }} onClick={(e) => {
                      e.preventDefault();
                      createCampaign()
                    }}>Begin campaign</Button>
                  </div>
                </div>

              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
              <Card bordered={false} >
                <div className="project-ant">
                  <div>
                    <Title level={5}>Campaigns</Title>
                  </div>
                  <div className="ant-filtertabs">
                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                      <Radio.Group defaultValue="a">
                        <Radio.Button onClick={() => navigate("/purchases")} value="a">Buy packages or credits</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="ant-list-box table-responsive" style={{ maxHeight: 300, overflowY: 'auto' }}>
                  <div style={{ maxHeight: 300 }}>

                    <table className="width-100" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: 5, paddingRight: 5, textAlign: "center" }}>Name</th>
                          <th style={{ paddingLeft: 2, paddingRight: 2, textAlign: "left" }}>Progress</th>
                          <th style={{ paddingLeft: 8, paddingRight: 8, textAlign: "center" }}>Controls</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaigns.map((campaign, index) => (
                          <tr key={campaign.name + index}
                            onClick={() => { }}
                          >
                            <td style={{ textAlign: "center", padding: 5 }}>
                              <h6>
                                <img
                                  src={ava5}
                                  alt={""}
                                  className="avatar-sm"
                                  style={{ display: "inline" }}
                                /><br></br>{campaign.name}
                              </h6>
                            </td>
                            <td style={{ padding: 2 }}>
                              <div className="percent-progress">
                                <span className="text-xs font-weight-bold">
                                  {campaign.status == 0 ? <Progress percent={campaign.progress_percent}></Progress> :
                                    campaign.status == 1 ? "Paused" :
                                      campaign.status == 2 ? "Stopped" :
                                        campaign.status == 3 ? "Complete" : ""}

                                </span>
                              </div>
                            </td>
                            <td style={{ padding: 8 }}>
                              {campaign.status == 0 ? <div style={{ textAlign: "center" }}>
                                <a onClick={pauseJob.bind(null, campaign.id)}>Pause</a><br /><br />
                                <a onClick={stopJob.bind(null, campaign.id)}>Stop</a>
                              </div> :
                                campaign.status == 1 ? <div style={{ textAlign: "center" }}>
                                  <a onClick={continueJob.bind(null, campaign.id)}>Continue</a><br /><br />
                                  <a onClick={stopJob.bind(null, campaign.id)}>Stop</a>
                                </div> :
                                  campaign.status == 2 ? <div>
                                  </div> :
                                    campaign.status == 3 ? <div>
                                    </div> : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
              <Card bordered={false} style={{ marginTop: 10 }}>
                <div className="timeline-box">
                  <Title level={5}>Outreach FAQ</Title>
                  <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                  </Paragraph>
                  <div style={{ maxHeight: 300, overflow: 'auto' }}>
                    <Timeline
                      style={{ padding: 10 }}
                    >
                      {faq.map((question, index) => (
                        <Timeline.Item color={"blue"} key={question.question + question.answer + index}>
                          <Title level={5}>{question.question}</Title>
                          <Text>{question.answer}</Text>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

      </Main>
    </>
  )
}

export default Outreach