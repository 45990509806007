/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  Row,
  Col,
  Card,
  Statistic,
  Button,
  List,
  Descriptions,
  Avatar,
  Typography,
  Input

} from "antd";
import countriesData from 'country-list-js'
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import Main from "../components/layout/Main";
import { React, useEffect, useState } from "react";
import CookieConsent from "../../../components/CookieConsent";
import CryptoPaymentModal from "../components/CryptoPaymentModal";


const allCountries = []

const skroldubina = { value: 0 }

const plans = [
  { name: "Free", limit: 500, price: 0, can_subscribe: false },
  //{ name: "Test1", limit: 1000, price: 1, can_subscribe: true, lookup: "test1" },
  //{ name: "Test2", limit: 2000, price: 2, can_subscribe: true, lookup: "test2" },
  { name: "Startup", limit: 10000, price: 10, can_subscribe: true, lookup: "startup" },
  { name: "Business", limit: 100000, price: 50, can_subscribe: true, lookup: "business" },
  { name: "Enterprise", limit: 500000, price: 100, can_subscribe: true, lookup: "enterprise" },
  { name: "Unlimited", limit: 0, price: 200, can_subscribe: true, lookup: "unlimited" },
]


function Purchases() {
  const cookies = new Cookies();
  const token = cookies.get('token')
  const [countries, setCountries] = useState([])
  const [curPacages, setCurPacages] = useState([]);
  const [curCountry, setCurCountry] = useState({});
  const [transactions, setTransactions] = useState([])
  const [buyCreditsAmount, setBuyCreditsAmount] = useState(2);
  const [ipAdress, setIpAddress] = useState("")
  const [userCountry, setUserCountry] = useState("US")
  const [searchCurrencyText, setSearchCurrencyText] = useState("")
  const { Title, Text } = Typography;
  const [credits, setCredits] = useState(0)

  const [btcSelected, setBtcSelected] = useState(false)
  const [cryptoPaymentModalShown, setCryptoPaymentModalShown] = useState(false);
  const [amountInChosenCrypto, setAmountInChosenCrypto] = useState(0)
  const [walletAddress, setWalletAddress] = useState("")



  let resetScroll = () => {
    let val = skroldubina.value;
    if (val)
      window.scrollTo(0, val);
  };

  useEffect(resetScroll, [ipAdress, userCountry, credits, countries, curCountry, transactions, curPacages, buyCreditsAmount, searchCurrencyText]);

  let navigate = useNavigate();

  const data = [
    {
      title: "March, 01, 2021",
      description: "#MS-415646",
      amount: "$180",
    },
    {
      title: "February, 12, 2021",
      description: "#RV-126749",
      amount: "$250",
    },
    {
      title: "April, 05, 2020",
      description: "#FB-212562",
      amount: "$550",
    },
    {
      title: "June, 25, 2019",
      description: "#QW-103578",
      amount: "$400",
    },
    {
      title: "March, 03, 2019",
      description: "#AR-803481",
      amount: "$700",
    },
  ];

  const download = [
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key="0"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L9 10.5858L9 3C9 2.44772 9.44771 2 10 2C10.5523 2 11 2.44771 11 3L11 10.5858L12.2929 9.29289C12.6834 8.90237 13.3166 8.90237 13.7071 9.29289C14.0976 9.68342 14.0976 10.3166 13.7071 10.7071L10.7071 13.7071C10.5196 13.8946 10.2652 14 10 14C9.73478 14 9.48043 13.8946 9.29289 13.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289Z"
        fill="#111827"
      ></path>
    </svg>,
  ];

  const getCountry = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    skroldubina.value = window.scrollY;
    setIpAddress(data.ip);
    fetch(global.SERVIP + '/user/get-ip-info/' + data.ip, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(res => {
        skroldubina.value = window.scrollY;
        setUserCountry(res);
      })
  };

  useEffect(() => {
    if (!token || token.length == 0) {
      navigate('/signup')
      return
    }
    getCountry()
    console.log(countriesData.all)
    console.log(countriesData.findByIso2("US").currency)
    fetch(global.SERVIP + 'user/get-credits', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          skroldubina.value = window.scrollY;
          setCredits(response.credits);
        }
      })
      .catch(_ => { })
    const requestCountries = async () => {
      await fetch(global.SERVIP + 'countries', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              skroldubina.value = window.scrollY;
              setCountries(res)
              setCurCountry(res[0])
              getPacagesFromCounty(res[0])

            })
          }
          else
            alert('Error')
        })
    }
    const requestTranslations = async () => {
      await fetch(global.SERVIP + 'user/transactions', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              skroldubina.value = window.scrollY;
              setTransactions(res)
            })
          }
          else
            alert('Error')
        })
    }
    requestCountries()
    requestTranslations()
  }, []);

  async function getPacagesFromCounty(item) {
    await fetch(global.SERVIP + 'countries/' + item.id + '/packages', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(res => {
            skroldubina.value = window.scrollY;
            setCurPacages(res);
            setCurCountry(item)
          })
        }
        else
          alert('Error')
      })
  }

 async function initiatePurchase(item) {
    if (global.waitingPayment) return;
console.log(item)
    if (btcSelected)
    {
      await createCryptoPayment (true, item.id, item.price*100)
        return;
    }
    global.waitingPayment = true;

    let id = item.id;
    fetch(global.SERVIP + 'user/packages/pay', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ id, country: countriesData.findByIso2(userCountry).code.iso2 })
    })
      .then(res => res.json())
      .then(res => {
        if (res.ok) {
          global.waitingPayment = false;
          window.purchaseAmount = item.price;
          window.location.assign(res.url);
        }
        else {
          global.waitingPayment = false;
          alert(res.message);
        }
      })
      .catch(res => {
        global.waitingPayment = false;
        alert("Error requesting payment!");

      })
    //navigate('/payment', { state: { package: item } })
  }
  const goToBottom = () => {
    window.scrollTo({
      top: 1000,
      behavior: 'smooth',
    });
  };

  const createCryptoPayment = async (isPackage, transactionValue, usdValue) => {

    const payload = {
      isPackage: isPackage,
      transactionValue: transactionValue,
      usdValue: usdValue,
    };

    try {
      const response = await fetch(global.SERVIP+'/crypto/create-payment', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data.data);
      alert('Payment registered successfully');
      setCryptoPaymentModalShown(true)
      setAmountInChosenCrypto(data.data.amountInCrypto/100000000)
      setWalletAddress(data.data.wallet)

    } catch (error) {
      console.error('Error registering payment:', error);
      alert('Error registering payment');
    }
  };
  return (
    <>
      <Main>
      <div style={{ backgroundColor: '#d9a64a', padding: '10px', textAlign: 'center', marginBottom: '20px' }}>
          <Typography.Title level={4} style={{ color: 'white', margin: 0,  }}>
            We Now Accept Instant Crypto Payments!
          </Typography.Title>
          <a  style={{ color: 'white', margin: 0,  }}>Go to choose currency and select BTC to pay with crypto.</a>
        </div>
        <CookieConsent></CookieConsent>
        <Row>
          <a style={{ fontWeight: 'bold' }}>
            We have 3 pricing options. The first two are credits and packages, credits are better for sending smaller amount of messages worldwide and packages are better for sending larger amount of texts to a certain region. We also have an option to use your own pool of numbers. Check out all three and pick the one that best fits your needs.
          </a>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={24} md={16} className="mb-24">
            <div>
              <h2>Pay as you go</h2>

              <p>Send SMS <span style={{ color: "#999999", fontStyle: "italic" }} title="Currently, we are experiencing a problem with sending messages to Russia.">anywhere in the world*</span> with our flexible pricing model (starting at less than $0.015 per SMS). You can view the whole pricing list at this <a style={{ color: "blue" }} href="/pay-as-you-go">link.</a>
                <a> (Better for smaller volumes ~50$)</a>
                <a> Current currency : {countriesData.findByIso2(userCountry).currency.code}</a> <span title="Choose a currency to be billed in." style={{ color: "#999999", fontStyle: "italic" }} onClick={() => goToBottom()} >,want to change it ?</span>
              </p>

              <Input
                type="number"
                min={2}
                placeholder="Amount in USD" style={{ width: 80 }} value={buyCreditsAmount} onChange={e => {
                  skroldubina.value = window.scrollY;
                  setBuyCreditsAmount(e.target.value)
                }}></Input>
              <a style={{ fontSize: 20, fontWeight: 'bold', margin: 5 }}>$</a>
              <button
                class="ant-btn css-dev-only-do-not-override-1n7nwfa ant-btn-primary"
                style={{ margin: 10, backgroundColor: "#6A76EC" }}
                onClick={async() => {
                  if (Math.ceil(buyCreditsAmount * 100) < 200) return alert("Minimum is 2$");
                  let creditsn = Math.ceil(buyCreditsAmount * 100);
                  if(btcSelected)
                  {
                    await createCryptoPayment(false, creditsn, creditsn)
                    return;
                  }
                  fetch(global.SERVIP + 'user/credits/pay', {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify({ amount: creditsn, outreach: false, country: userCountry })
                  })
                    .then(response => response.json())
                    .then(response => {
                      if (!response) return
                      if (response.ok) {
                        window.purchaseAmount = creditsn;
                        window.location.assign(response.url);
                      }
                      else
                        alert(response.message);
                    })
                    .catch(e => {
                      console.log(e);
                    })
                }}> <h4 style={{ color: 'white' }} class="ant-list-item-meta-title">Buy</h4></button>
              <p style={{ fontSize: 20 }}>You have ${credits} in credits</p>

            </div>
            <h2>Send SMS for free</h2>
            <p>You can use our open source tool to send SMS for free, using your own server, that you can send API requests to, just like TextFlow, and you own android phones with SIM cards. <a href="https://docs.textflow.me/send-sms-for-free">Learn more</a></p>

            <p>If you are not that much into tech, or want to use our outreach tool to send load-balanced promotional SMS from your SIM cards, you can use our "Use your numbers" solution, for a fraction of price of using packages or credits. </p>

            <br></br>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", textAlign: "center" }}>
              {plans.map(e => <div style={{ margin: 2 }} key={e.name}>
                <Title level={5}>{e.name}</Title>
                <Text>{e.limit ? ((e.limit / 1000) + "k SMS / month") : "No limit"}</Text><br></br>
                {e.price ? <span><Text>{e.price + "$ / month"}</Text><br></br></span> : null}
              </div>)}
            </div>
            <br></br>
            <div style={{ textAlign: "center" }}> <button
              class="ant-btn css-dev-only-do-not-override-1n7nwfa ant-btn-primary"
              style={{ margin: 10, backgroundColor: "#6A76EC", color: 'white' }}
              onClick={() => {
                window.location.assign("smsgateway");
              }}>Get started</button> </div>
            <br></br>
            <h2>Purchase packages</h2>
            <p> If you want to send a large number of SMS to a specific location, this option is perfect for you</p>
            <h3 class="ant-list-item-meta-title">Message amounts</h3>
            <Row style={{ marginBottom: 10, display: 'flex', justifyContent: 'center', padding: 10 }}>
              {countries.map((country, index) =>
                <button type="button" class="ant-btn css-dev-only-do-not-override-1n7nwfa"
                  onClick={async () => await getPacagesFromCounty(country)}
                  style={{ margin: 10, color: 'white', backgroundColor: country.name == curCountry.name ? "black" : "", }}>
                  <h4 style={{ color: country.name == curCountry.name ? "white" : '#6A76EC' }} class="ant-list-item-meta-title">{country.name}</h4>
                </button>
              )}
            </Row>
            <Card
              className="header-solid h-full"
              bordered={false}
              title={[]}
              style={{ maxHeight: '70vh', overflow: 'auto' }}
            >
              <Row gutter={[24, 24]} >
                {curPacages.sort(function (a, b) { return b.pricePerMessageUSD - a.pricePerMessageUSD }).map((item, index) => (
                  <Col span={24} key={index} >
                    <Card className="card-billing-info" bordered="false">
                      <div className="col-info">
                        <Descriptions title={item.messageLimit + ' messages'}>

                          <Descriptions.Item label="Price " span={3}>
                            {Math.round(item.messageLimit * item.pricePerMessageUSD)} $
                          </Descriptions.Item>
                          <Descriptions.Item label="Price per message" span={3}>
                            {item.pricePerMessageUSD + ' $ per message'}
                          </Descriptions.Item>

                        </Descriptions>
                      </div>
                      <div className="col-action">
                        <button
                          onClick={() => initiatePurchase({ id: item.id, messageLimit: item.messageLimit, price: Math.round(item.messageLimit * item.pricePerMessageUSD), countries: curCountry.name })}
                          class="ant-btn css-dev-only-do-not-override-1n7nwfa ant-btn-primary"
                          style={{ margin: 10, backgroundColor: "#6A76EC" }}>
                          <h4 style={{ color: 'white' }} class="ant-list-item-meta-title">Purchase</h4>
                        </button>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
          <Col span={24} md={8} className="mb-24">
            <Card
              style={{ maxHeight: '40vh' }}
              bordered={false}
              className="header-solid h-full ant-invoice-card"
              title={[<h6 className="font-semibold m-0">Purchase History</h6>]}
              extra={[
                <Button type="primary">
                  <span>VIEW ALL</span>
                </Button>,
              ]}
            >
              <List
                itemLayout="horizontal"
                className="invoice-list"
                dataSource={transactions}
                renderItem={(item) => (
                  <List.Item

                  >
                    <List.Item.Meta
                      title={"Package id : " + item.id}
                      description={new Date(item.createdAt).toDateString()}
                    />
                    <div className="amount">{item.amount + " $"}</div>
                  </List.Item>
                )}
              />
            </Card>
            <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 200, marginTop: 10 }}>
  <h2>Select a currency</h2>
  <a>Choose a currency in which you want to pay by entering a country or currency name. </a>
  <span style={{ color: "#999999", fontStyle: "italic" }} > *All of the prices are shown is USD for regulatory reasons, but you will be charged in the currency you have selected. </span>
  <a>Current currency : </a>
  <a style={{ fontWeight: 'bold' }}>{" " + countriesData.findByIso2(userCountry).currency.code + " "}{countriesData.findByIso2(userCountry).currency.code != countriesData.findByIso2(userCountry).currency.symbol ? countriesData.findByIso2(userCountry).currency.symbol : ""}</a>
  <br></br>
  <Input placeholder="Country or currency" onChange={(e) => {
    skroldubina.value = window.scrollY;
    setSearchCurrencyText(e.target.value)
  }} style={{ width: "100%", marginTop: "10px" }}></Input>
  <div style={{ height: 100, overflow: 'auto', backgroundColor: "white", width: "100%", marginTop: 5, display: 'flex', flexDirection: 'column' }}>
    {countriesData.names().filter(it => it.toLowerCase().includes(searchCurrencyText.toLowerCase()) || countriesData.findByName(it).currency.code.toLowerCase().includes(searchCurrencyText.toLowerCase()) || countriesData.findByName(it).currency.symbol.toLowerCase().includes(searchCurrencyText.toLowerCase())).map(item => (
      <a style={{ backgroundColor: item == countriesData.findByIso2(userCountry).name ? "#6A76EC" : "", color: item == countriesData.findByIso2(userCountry).name ? "white" : "black" }} onClick={() => {
        skroldubina.value = window.scrollY;
        setUserCountry(countriesData.findByName(item).code.iso2)
      }}>
        {item + " | "}
        {countriesData.findByName(item).currency.code}
      </a>
    ))}
  </div>
  <br></br>
  <div style={{ display: "flex", flexDirection: "column" }}>
    <h2>Want to pay with crypto?</h2>
    <h4>10% discount on all crypto payments</h4>
    <p>If you want to pay with Bitcoin, you can use this. Just check the box and choose which package/amount of credits you want to buy. If you want to pay with any other crypto currency, you can reach us at sales@textflow.me. Below are the wallets for reference</p>
    <br></br>

    <div style={{ padding: '10px', margin: '5px', border: '2px solid orange', borderRadius: '5px', display: 'inline-block', backgroundColor: '#FFF7E6' }}>
  <input type="checkbox" id="payWithBTC" name="payWithBTC" style={{ width: "20px", height: "20px", accentColor: 'orange', transform: 'scale(1.5)' }} value={btcSelected} onChange={() => setBtcSelected(!btcSelected)} />
  <label htmlFor="payWithBTC" style={{ marginLeft: '10px', fontWeight: 'bold', fontSize: '18px', color: 'orange', textShadow: '1px 1px 0px #fff' }}>Pay with BTC</label>
</div>



    <br />

    <a>Eth: </a>
    <a>0xeAE15d75b15c186aE32ed07357f082F8b278bD40</a>
    <br></br>

    <a>Litecoin: </a>
    <a>ltc1q5n777autu43f5yc52zhhnuelgyfu8pkluu56va</a>
    <br></br>

    <a>Dogecoin: </a>
    <a>DEQ9gXgXYFNLSdQWYxgpyQndHTYytUCUBY</a>
  </div>
</Card>

          </Col>
        </Row>
        <CryptoPaymentModal
        cryptoPaymentModalShown={cryptoPaymentModalShown}
        setCryptoPaymentModalShown={setCryptoPaymentModalShown}
        amountInChosenCrypto={amountInChosenCrypto}
        walletAddress={walletAddress}
        selectedCurrency={"BTC"}
      />      </Main>

    </>
  );
}

export default Purchases;
