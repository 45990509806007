import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import featureImg7 from '../../../assets/images/feature/feature_img7.png'
import CodeSippetTabs from '../../../../../../components/CodeSnippetTabs'
import {languages} from '../../../../../../data/snippets'

const UseYourNumbersDescriptionService = () => {
    return (
        <>
            <section className="sc-service-section-area sc-pt-210 sc-md-pt-90 sc-pb-140 sc-md-pb-50 sc-pb-30 container-fluid2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="sc-heading-area text-center sc-mb-60">
                                <h2 className="heading-title">Use all of our services and send the messages from your own mass sms pool of numbers.</h2>
                            </div>
                        </div>
                    </div>


            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center" style={{display: 'flex', justifyContent: 'center'}}>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">How does it work?</span>
                                        <h2 className="heading-title sc-mb-25">Mass SMS pool</h2>
                                        <div className="description sc-mb-25">
                                            This is a part of our service which lets you use your own numbers as SMS gateways.
                                            You will need to install <a>our app</a> on at least one android device, and log in using your TextFlow account
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                    <ul style={{listStyle:'none', padding:0}}>
                                            <li><i className="ri-checkbox-circle-fill"></i>Cheap: You get all of our API's capabilities for free up to 500 messages per month, after that, it's still just a fraction of the price.Check out our SMS Pool pricing <a>here</a></li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Seamless Experience: Simplify verification with a user-friendly API for a hassle-free experience.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Preventive measures: We help you set everything up so that your numbers don't get blacklisted by the carriers.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade left>
                        <div className="row align-items-center" style={{backgroundColor: '#101621', borderRadius:10, padding:10}}>
                      
                    </div>
                        </Fade>
                    </div>
                </div>
            </section>
            <div className="btn-area sc-mt-40" style={{display: 'flex', flexDirection: 'column',justifyContent: 'center',alignItems: 'center'}}>
                                        <a className="primary-btn" href="/signup" style={{width: 200}}><span>Get Started</span></a>
                                    </div>

                </div>
            </section>
        </>
    );
};

export default UseYourNumbersDescriptionService;