import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import NavBar from '../../components/Navbar';
import './Pricing.css'
import Footer from "../user/components/layout/Footer";

import {
    Row,
    Col,
    Card,
    Statistic,
    Button,
    List,
    Descriptions,
    Avatar,
    Typography,

} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import CookieConsent from '../../components/CookieConsent';
//fetch pacage levels to get max messages

// when clicked on max messages show all pacages that match that pacage level

function Legal() {
    return (
        <div style={{ backgroundColor: '#f5f5f5' }}>
            <div className="container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Col span={24} md={16} className="mb-24">
                    <Card
                        className="header-solid h-full"
                        bordered={false}
                        title={[<h3  >Impressum information</h3>]}
                        bodyStyle={{ paddingTop: "0" }}
                        style={{ backgroundColor: "#f5f5f5" }}
                    >
                        <h3 class="ant-list-item-meta-title">Textflow Ltd</h3>
                        <h3 class="ant-list-item-meta-title">71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ </h3>
                        <h3 class="ant-list-item-meta-title">Company number 14669479</h3>
                        <h3 class="ant-list-item-meta-title"><a style={{ color: "darkgray" }} href='tel:+381690156360'>+381 69 0156360</a> </h3>
                        <h3 class="ant-list-item-meta-title"><a style={{ color: "darkgray" }} href='mailto:support@textflow.me'>support@textflow.me</a> </h3>
                        <Row style={{ marginBottom: 10, display: 'flex', justifyContent: 'center', padding: 10 }}>
                        </Row>
                        <Row gutter={[24, 24]}>
                        </Row>
                    </Card>
                    <Card
                        className="header-solid h-full"
                        bordered={false}
                        title={[<h3 >Disclaimer</h3>]}
                        bodyStyle={{ paddingTop: "0" }}
                        style={{ backgroundColor: "#f5f5f5" }}
                    >
                        <p style={{ textAlign: "left" }}>If you require any more information or have any questions about our site's disclaimer, please feel free to contact us by email at support@textflow.me. Our Disclaimer was generated with the help of the <a style={{ color: "darkgray" }} href="https://www.termsfeed.com/disclaimer-generator/">Free Disclaimer Generator</a>.</p>

                        <h4>Disclaimers for TextFlow</h4>

                        <p style={{ textAlign: "left" }}>All the information on this website - https://textflow.me - is published in good faith and for general information purpose only. TextFlow does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (TextFlow), is strictly at your own risk. TextFlow will not be liable for any losses and/or damages in connection with the use of our website.

Additionally, please note that TextFlow respects the privacy of its users and adheres to all relevant data protection laws. We only send notification and promotional messages to users who have given their explicit consent. When you use our website, we may collect certain information about you. However, we will never share your personal information with third parties without your consent.

From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.

Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>

                    
                        <h4>Consent</h4>

                        <p style={{ textAlign: "left" }}>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>

                        <h4>Update</h4>

                        <p style={{ textAlign: "left" }}>Should we update, amend or make any changes to this document, those changes will be prominently posted here.</p>

                    </Card>
                </Col>
            </div>

        </div>

    )
}

export default Legal