import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import aboutImage from '../../assets/images/about/about-image.png'
import aboutImage1 from '../../assets/images/about/about-img_1.png'

import noCode from '../../assets/images/banner/noCodeCampaign.png'

const NoCodeLanding = () => {
    return (
        <>
            <section className="sc-about-area gray-bg sc-pt-140 sc-pb-180 sc-md-pt-80 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade right>
                            <div className="col-lg-6" >
                                <div className="sc-about-item sc-pl-150">
                                    <div className="sc-heading-area sc-mb-30 sc-sm-mb-15">
                                        <h2 className="heading-title">Our online Bulk SMS tool</h2>
                                    </div>
                                    <div className="description sc-mb-25 sc-sm-mb-15" >
                                    
In need of a simple way to send bulk SMS or mass text messages? With our platform, reaching your customers with new product notifications, holiday discounts, or promotional SMS has never been easier. Just upload a file with your customers' phone numbers, craft your message, set the duration, and that's it. No coding experience necessary. From bulk SMS services to promotional mass messaging, we've got your communication needs covered efficiently and reliably.
                                    </div>
                                    <div className="btn-area sc-mt-40" style={{display: 'flex', flexDirection: 'column'}}>
                                        <a className="primary-btn" href="/bulksms"><span>More ...</span></a>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade left>
                            <div className="col-lg-6" >
                                <div className="image-area sc-md-mb-80">
                                    <div className="sc-about-image" style={{marginTop:40}}>
                                        <img className="about-image" src={noCode} alt="aboutImage" />
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NoCodeLanding;