import {React,useState,useEffect} from "react";
import Cookies from 'universal-cookie';
import {
    Layout,
    Menu,
    Button,
    Row,
    Col,
    Typography,
    Form,
    Input,
    Switch,
  } from "antd";
  import signinbg from "../assets/images/img-signin.jpg";
  import { Link } from "react-router-dom";
  import { useNavigate } from "react-router-dom";

function RestartPassword(){
    const cookies = new Cookies();
    const token = cookies.get('token');
    const { Title } = Typography;
    const { Header, Footer, Content } = Layout;

    let navigate = useNavigate();

    const [email,setEmail] = useState("")
    const [emailSent,setEmailSent] = useState(false)
    const [newPassword,setNewPassword] = useState("")
    const [code,setCode] = useState("")

    async function restartPasswordRequest(req, res){
      await fetch(global.SERVIP + 'users/password/restart', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({newPassword:newPassword, code:code, email:email })
      })
        .then(response => {
          if (response.ok) {
            alert("Password successfuly changed")
            navigate('/login')

          }
          else {
            alert('Error, wrong code')
          }
        })
        .catch(e => {
          console.log("Wrong password");
        })
    }
    async function sendPasswordRestartMail(req, res) {
      await fetch(global.SERVIP + 'users/password/sendrestartmail', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email:email})
      })
        .then(response => {
          if (response.ok) {
            setEmailSent(true)
            alert("Code should be sent shortly")
          }
          else {
            alert('Email not registered or uses google sign-in')
          }
        })
        .catch(e => {
          console.log("Wrong password");
        })
    }

    return (
      !emailSent?
      <div>
            <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
              <Col
              >
                <Title className="font-regular text-muted" style={{marginTop:100}} level={5}>
                  Enter your email adress.
                </Title>
                <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input your email adress",
                      },
                    ]}
                  >
                    <Input
                    name = "email"  
                    onChange={(e)=>{
                      setEmail(e.target.value)
                      }} placeholder="Email" />
                  </Form.Item>
                    <Button
                      type="primary"
                      style={{ width: "100%" }}
                      onClick={async()=>await sendPasswordRestartMail()}
                    >
                      Send code to my email address.
                    </Button>
                             
              </Col>
            </Row>
          </Content>
        </div>
      :
        <div>
            <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
              <Col
              >
                <Title className="font-regular text-muted" style={{marginTop:100}} level={5}>
                  Enter your new password, and a code we sent to you email adress.
                </Title>
                <Form

                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    label="New password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your new password",
                      },
                    ]}
                  >
                    <Input onChange={(e)=>setNewPassword(e.target.value)} placeholder="password" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Code"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: "Please input your code",
                      },
                    ]}
                  >
                    <Input onChange={(e)=>setCode(e.target.value)} placeholder="Code" />
                  </Form.Item>

                 

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      onClick={async() => await restartPasswordRequest()}
                    >
                      Change password
                    </Button>
                  </Form.Item>
                  <p className="font-semibold text-muted">
                    Forgot your password?{" "}
                    <Link to="/sign-up" className="text-dark font-bold">
                      Restart it
                    </Link>
                  </p>
                </Form>
              </Col>

            </Row>
          </Content>
        </div>
    )
}

export default RestartPassword;