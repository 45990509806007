import { useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';
import Cookies from 'universal-cookie';

let uradjen = false;

function PaymentSuccessOutreach() {
  const cookies = new Cookies();

  const handlePurchase = (productPrice) => {
    window.gtag('event', 'purchase', {
      'items': [
        {
          'item_name': "credits",
          'price': productPrice
        }
      ]
    });
  };

  useEffect(() => {
    if (uradjen) return;
    uradjen = true;

    if (cookies.get('targeting_cookies') === 'true') {
      ReactPixel.track('Purchase', { value: 5, currency: 'USD' });
      handlePurchase(window.purchaseAmount);
    }

    alert("Payment successfully received! It may take up to 5 minutes for the payment to process.");
    window.location.replace("https://textflow.me/outreach");
  }, []);  // Empty dependency array to ensure this effect runs only once.

  return <div></div>
}

export default PaymentSuccessOutreach;