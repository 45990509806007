import React from 'react';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ContactInfo = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("")
    const handleMessage = (event) => {
        event.preventDefault()
        event.target.reset()
        toast("Message Sent Successfully")
    }
    const cookies = new Cookies();
    let navigate = useNavigate();
  
    useEffect(() => {
      if (cookies.get('token') != null && cookies.get('token').length > 0) {
        navigate('/dashboard')
      }
    }, [])


    const onFinish = values => {
      console.log('Success:', values);
    };
  
    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };
    async function sendEmail() {
  
      await fetch(global.SERVIP + 'email/send', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message, email })
      })
        .then(response => {
          if (response.ok) {
            alert("Email sent")
            response.json().then(res => {
              alert(res)
            })
          }
          else
            alert('Error')
        })
    }
    return (
        <>
            <section>
                <div className="sc-contact-pages-area sc-pt-140 sc-md-pt-70 sc-pb-20 sc-md-pb-10 ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="sc-heading-area sc-mb-20">
                                    <span className="sc-sub-title primary-color">Get In Touch</span>
                                    <h2 className="heading-title sc-mb-45">Our Team Is Ready To Support You</h2>
                                </div>
                                <div className="contact-page-service-box">
                                   
                                    <div className="contact-service-box" style={{display:'flex', alignItems:'flex-start'}}>
                                        <ul>
                                            <li>71-75 Shelton Street Covent Garden, London W2CH 9JQ,<span> United Kingdom</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="contact-page-service-box" >
                                    <div className="contact_icon">
                                        <i className="ri-mail-fill"></i>
                                    </div>
                                    <div className="contact-service-box" style={{display:'flex', alignItems:'flex-start', textAlign:'left'}}>
                                        <ul className="contact-list">
                                            <li><a href="mailto:support@textflow.me">support@textflow.me</a></li>
                                            <li><a href="mailto:sales@textflow.me">sales@textflow.me</a></li>
                                            <li><a href="mailto:aaron@textflow.me">aaron@textflow.me</a></li>
                                        </ul>
                                    </div>
                                </div>
                              
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-box sc-md-mb-10">
                                    <form onSubmit={sendEmail}>
                                        <div className="login-form">
                          
                                            <div className="form-box">
                                                <label className="sc-mb-10">Email*</label>
                                                <input  onChange={(e)=>{setEmail(e.target.value)}} className="from-control" type="email" id="email" name="email" placeholder="Write Your Email" required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label>Message*</label>
                                                <textarea onChange={(e)=>{setMessage(e.target.value)}}  id="message" name="message" placeholder="Write Your Message" autoComplete="off" required></textarea>
                                            </div>
                                        </div>
                                        <div className="submit-button">
                                            <input className="submit-btn" type="submit" value="Send Message" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactInfo;