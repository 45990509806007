import csCode from "./csCode";
import javaCode from "./javaCode";
import jsCode from "./jsCode";
import phpCode from "./phpCode";
import pythonCode from "./pythonCode";

const languages = [{
    id: "js",
    name: "Node.js",
    code: jsCode,
    docsUrl: "https://docs.textflow.me/node",
    githubUrl: "https://github.com/Skocimis/textflow.js",
    installation: {
        language: "bash",
        code: "$npm i textflow.js"
    }
},
{
    id: "python",
    name: "Python",
    code: pythonCode,
    docsUrl: "https://docs.textflow.me/python",
    githubUrl: "https://github.com/Skocimis/textflow-python",
    installation: {
        language: "bash",
        code: "$pip install textflowsms"
    }
},
{
    id: "cs",
    name: "C#",
    code: csCode,
    docsUrl: "https://docs.textflow.me/csharp",
    githubUrl: "https://github.com/Skocimis/textflow-csharp",
    installation: {
        language: "bash",
        code: "$dotnet add package TextFlow"
    }
},
{
    id: "php",
    name: "PHP",
    code: phpCode,
    docsUrl: "https://docs.textflow.me/php",
    githubUrl: "https://github.com/Skocimis/textflow-php",
    installation: {
        language: "bash",
        code: "$composer require textflow/sms-api"
    }
},
{
    id: "java",
    name: "Java",
    code: javaCode,
    docsUrl: "https://docs.textflow.me/java",
    githubUrl: "https://github.com/Skocimis/java-textflow-client",
    installation: {
        language: "xml",
        code: `<dependency>
    <groupId>me.textflow</groupId>
    <artifactId>client</artifactId>
    <version>1.0.1</version>
</dependency>`
    }
},
{
    id: "endpoints",
    name: "API endpoints",
    docsUrl: "https://docs.textflow.me/",
    githubUrl: "https://docs.textflow.me/",
    code: [
        {
            name: "Common attributes of every request",
            code: `{
    "method": "POST",
    "headers": {
        "Content-Type": "application/json"
        "Authorization": "Bearer "+"YOUR_API_KEY"
    },
}`
        },
        {
            name: "Sending an SMS",
            code: `{
    "url": "https://textflow.me/api/send-sms",
    "body": {
        "phone_number": "+11234567890",
        "text": "This is an SMS. "
    }
}`,
            language: "json"
        },
        {
            name: "Sending OTP verification",
            code: `{
    "url": "https://textflow.me/api/send-code",
    "body": {
        "phone_number": "+11234567890",
        "service_name": "My Service",
        "seconds": 900
    }
}`,
        },
        {
            name: "Verifying the OTP code",
            code: `{
    "url": "https://textflow.me/api/verify-code",
    "body": {
        "phone_number": "+11234567890",
        "code": "123456"
    }
}`,
        }
    ]
}];

export {
    languages
};