import {React,useState,useEffect} from "react";
import Cookies from 'universal-cookie';
import {
    Layout,
    Menu,
    Button,
    Row,
    Col,
    Typography,
    Form,
    Input,
    Switch,
  } from "antd";
  import signinbg from "../assets/images/img-signin.jpg";
  import { Link } from "react-router-dom";
  import { useNavigate } from "react-router-dom";

function ChangePassword(){
    const cookies = new Cookies();
    const token = cookies.get('token');
    const { Title } = Typography;
    const { Header, Footer, Content } = Layout;
    let navigate = useNavigate();
    const [password,setPassword] = useState("")
    const [newPassword,setNewPassword] = useState("")

    async function changePasswordRequest(){
      await fetch(global.SERVIP + 'users/password/change', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({password:password, newPassword:newPassword })
      })
        .then(response => {
          if (response.ok) {
            alert("Password successfuly changed")
            cookies.remove('token')
            navigate('/login')

          }
          else {
            alert('Error, wrong password')
          }
        })
        .catch(e => {
          alert('Error, wrong password')
          console.log("Wrong password");
        })
    }
    useEffect(() => {  
      if(!token || token.length == 0 )
      {
        navigate('/login')
        return
      }
    },[]);
   
    

    return (
        <div>
                      <Content className="signin">
            <Row gutter={[24, 0]} justify="space-around">
              <Col
              >
                <Title className="font-regular text-muted" style={{marginTop:100}} level={5}>
                  Enter your current password and a new password.
                </Title>
                <Form

                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Current password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your current password",
                      },
                    ]}
                  >
                    <Input onChange={(e)=>setPassword(e.target.value)} placeholder="password" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="New password"
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input onChange={(e)=>setNewPassword(e.target.value)} placeholder="Password" />
                  </Form.Item>

                 

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      onClick={async()=>await changePasswordRequest()}
                    >
                      Change password
                    </Button>
                  </Form.Item>
                  <p className="font-semibold text-muted">
                    Forgot your password?{" "}
                    <Link to="/restartpassword" className="text-dark font-bold">
                      Restart it
                    </Link>
                  </p>
                </Form>
              </Col>

            </Row>
          </Content>
        </div>
    )
}

export default ChangePassword;