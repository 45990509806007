import { useState, useEffect } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Input,
} from "antd";
import {
  SearchOutlined,
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  MessageFilled,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Main from "../components/layout/Main";
import Cookies from 'universal-cookie';
import CodeSnippetTabs from "../../../components/CodeSnippetTabs";
import { useNavigate, useLocation } from "react-router-dom";
import { languages } from "../../../data/snippets";
import axios from "axios";
import countriesData from "country-list-js"
import CookieConsent from "../../../components/CookieConsent";


const faq = [
  { question: "Do i need to have my own android device to use this service?", answer: "Yes, this service works by using your phone as SMS gateway to send messages withour restrictions (You can send as many as you want but more than 10 per minute is not recomended)" },
  { question: "Can I use all TextFlow services with my numbers?", answer: "Yes, both SMS API and No-Code Bulk SMS tools are still available to you if you are using your own numbers." },
  { question: "Can i use this for free?", answer: "Yes, you can send/receive up to 500 messages from your own devices for free per month, after that you can choose a subscription which best fits your needs. You can also use our open-source tool to send larger volumes for free. ", },
  { question: "Can I use one phone number to send to multiple countries?", answer: "Yes, just add a sender for each country that you want to send SMS to from that number.  " },
  { question: "Will my numbers be banned", answer: "If you turn on safety, you are less likely to encounter problems. It all depends on which messages you send, how many and where." },
  { question: "What do the 'worldwide' and 'safety' checkboxes mean?", answer: "Safety means limiting your sender sending rate to 1SMS/6s, so it will be less likely to encounter problems. Worldwide means that if there are no other senders for the country that you are sending to, you will use that sender instead of your credits and packages. " },
  { question: "Am I also charged for the SMS that I receive?", answer: "Yes, all messages that your sender sends or your phone receives are present in your monthly usage." }
]

const skroldubina = { value: 0 }

const plans = [
  { name: "Free", limit: 500, price: 0, can_subscribe: false },
  //{ name: "Test1", limit: 1000, price: 1, can_subscribe: true, lookup: "test1" },
  //{ name: "Test2", limit: 2000, price: 2, can_subscribe: true, lookup: "test2" },
  { name: "Startup", limit: 10000, price: 10, can_subscribe: true, lookup: "startup" },
  { name: "Growth", limit: 30000, price: 25, can_subscribe: true, lookup: "growth" },
  { name: "Business", limit: 100000, price: 50, can_subscribe: true, lookup: "business" },
  { name: "Enterprise", limit: 500000, price: 100, can_subscribe: true, lookup: "enterprise" },
  { name: "Unlimited", limit: 0, price: 200, can_subscribe: true, lookup: "unlimited" },
]

function SmsGateway() {
  const location = useLocation()

  let resetScroll = () => {
    let val = skroldubina.value;
    if (val)
      window.scrollTo(0, val);
  };
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(location.state ? true : false);
  const [search, setSearch] = useState("");
  const [planId, setPlanId] = useState(0)
  const [usage, setUsage] = useState(3)
  const [senders, setSenders] = useState([]);
  const [brojac, setBrojac] = useState(0);
  const cookies = new Cookies();
  const token = cookies.get('token')
  let navigate = useNavigate();
  useEffect(resetScroll, [search, senders]);

  useEffect(() => {
    try {
      axios.post(global.SERVIP + "get-plan-info", {}, { headers: { 'Authorization': 'Bearer ' + token } }).then(result => {
        if (!result.data.ok) {
          console.log(result.data);
          return alert("Error")
        }
        setUsage(result.data.used)
        for (let i = 0; i < plans.length; i++) {
          if (plans[i].limit == result.data.limit)
            setPlanId(i)
        }
      })

    }
    catch (e) {
      console.log(e);
      alert("Error")
    }

    fetch(global.SERVIP + 'get-senders', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => response.json())
      .then(async res => {
        if (!res.ok) res.senders = []
        console.log("SENDERS");
        console.log(res.senders);
        setSenders(res.senders);
      })
      .catch(e => {
        setSenders([]);
      })
  }, [])
  return (
    (!loading) ?
      <>
        <Main>
          <CookieConsent></CookieConsent>
          <div>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
                <Card bordered={false} className="criclebox cardbody h-full">
                  <div className="project-ant">
                    <div>
                      <Title level={3}>Learn how you can use your own SMS gateway</Title>
                      <Paragraph className="lastweek">
                        Just create as many senders as you want by following the instructions below and you will be able to use all of our services to send SMS from your own phone number
                      </Paragraph>
                    </div>
                  </div>
                  <div style={{ padding: '24px' }}>
                    <Title level={5}>Follow a step by step video on setting up your own gateway.</Title>
                    <div style={{ position: "relative", paddingBottom: "56.25%" /* 16:9 */, paddingTop: 25, height: 0 }}>
                      <iframe style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} width="560" height="315" src="https://www.youtube.com/embed/3FKtFitR7jw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <Title style={{ marginTop: 10 }} level={5}>Download android app for you device</Title>
                    <Paragraph className="lastweek">
                      If you don't want to watch the full video, just download the app from the link below and sign in with your TextFlow account.
                      That's it, after doing that, you will be able to use our services from your own number/numbers.
                      <br />
                      <Button style={{ margin: 10 }} onClick={() => { window.location.href = global.SERVIP + "textflow.apk" }}>Download the required android app </Button>

                    </Paragraph>
                    <Paragraph className="lastweek">
                      You can find the application usage instructions <a href={"https://docs.textflow.me/using-sms-senders"}>here</a>.
                    </Paragraph>
                    <Title level={5}>Receiving SMS</Title>
                    <Paragraph className="lastweek">
                      If you would also like to use our solution to receive the SMS sent to your phone, you need to install  <a target="_blank" href={global.SERVIP + "textflow-receive.apk"}>the app for sending and receiving SMS</a>, set it as your <a target="_blank" href="https://www.maketecheasier.com/change-default-messaging-app-android/#default-apps-setting">default messaging app</a>, and from there, you can receive messages <a target="_blank" href={"https://docs.textflow.me/receiving-sms-replies"}>as you normally would</a>, but only the webhook option is available for receiving SMS using your phone numbers right now. Note that setting our app as your default messaging application will block you from receiving messaging using your previous default messaging app, so this option is not recommended for your main phone. If you already have an app for sending SMS, delete all senders from the phone you want to add, install the receiving app, and then re-add senders. <br></br>
                      <Button style={{ margin: 10 }} onClick={() => { window.location.href = global.SERVIP + "textflow-receive.apk" }}>Download the required recive sms android app </Button>

                    </Paragraph>
                  </div>
                  <div className="uploadfile shadow-none">


                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <div className="timeline-box">
                    <Title level={4}>Your gateway plan</Title>
                    <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                    </Paragraph>
                    <div style={{ maxHeight: 300, overflow: 'auto' }}>
                      <Timeline
                        className="timelinelist"
                        style={{ padding: 10 }}
                      >

                        <Timeline.Item color={"blue"} >
                          <Title level={5}>{plans[planId].name} plan</Title>
                          <Text>Monthly usage: {usage}{plans[planId].limit ? (" / " + plans[planId].limit) : ""}</Text><br></br>
                          {(plans[planId].limit && plans[planId].limit <= usage) ? (<span><Text style={{ color: "red" }}>Upgrade to continue sending/receiving</Text><br></br></span>) : null}
                          {plans[planId].price ? <Text>{plans[planId].price + "$ / month"}</Text> : null}

                        </Timeline.Item>


                      </Timeline>
                    </div>
                    {!planId || <Button
                      onClick={async () => {
                        try {
                          const result = await axios.post(global.SERVIP + "view-plan", {}, { headers: { 'Authorization': 'Bearer ' + token } });
                          if (!result.data.ok) {
                            console.log(result.data);
                            return alert("Error")
                          }
                          window.location.replace(result.data.url)
                        }
                        catch (e) {
                          console.log(e);
                          alert("Error")
                        }
                      }}
                      type="primary"
                      className="width-100"
                    >
                      Manage subscription
                    </Button>}
                  </div>
                  <div>
                    <Title level={4}>Other plans</Title>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", textAlign: "center", flexWrap: "wrap" }}>
                      {plans.filter((p, i) => (i != planId && !p.hidden)).map(e => <div style={{ margin: 5, padding: 5 }} key={e.name}>
                        <Title level={5}>{e.name}</Title>
                        <Text>{e.limit ? ((e.limit / 1000) + "k SMS / month") : "No limit"}</Text><br></br>
                        {e.price ? <span><Text>{e.price + "$ / month"}</Text><br></br></span> : null}
                        <Text>${e.limit ? (Math.round(e.price / e.limit * 100000) / 100000) : '0.0'} per SMS</Text><br></br><br></br>
                        {!planId && <Button onClick={async () => {
                          try {
                            const result = await axios.post(global.SERVIP + "buy-plan", { lookup_key: e.lookup }, { headers: { 'Authorization': 'Bearer ' + token } });
                            if (!result.data.ok) {
                              console.log(result.data);
                              return alert("Error")
                            }
                            window.location.replace(result.data.url)
                          }
                          catch (e) {
                            console.log(e);
                            alert("Error")
                          }
                        }}>Subscribe</Button>}
                      </div>)}
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
                <Card bordered={false} className="criclebox cardbody h-full" style={{ padding: 20 }}>
                  <Title>Your registered phone numbers</Title>
                  <Paragraph className="lastweek">
                    Once you create the sender for a number, it will appear here.
                  </Paragraph>
                  <Input placeholder="Search by country or phone number" type="text" value={search} onChange={e => {
                    skroldubina.value = window.scrollY;
                    setSearch(e.target.value)
                  }}></Input>
                  {senders.filter(e => {
                    return e.sim_name.toUpperCase().includes(search.toUpperCase()) || countriesData.findByIso2(e.country.toUpperCase())?.name?.toUpperCase().includes(search.toUpperCase())
                  }).map((item, index) => {
                    return <div style={{
                      display: "flex", flexDirection: "row", justifyContent: "space-between", margin: 5, backgroundColor: "#eee", padding: 10, borderRadius: 10, shadowColor: '#000', shadowOffset: { width: 1, height: 1 }, shadowOpacity: 0.3, shadowRadius: 2, elevation: 5, alignItems: "center", flexWrap: "wrap"
                    }}>
                      <img
                        src={"https://raw.githubusercontent.com/hampusborgos/country-flags/main/png250px/" + item.country + ".png"}
                        style={{ width: 80, height: 56, marginRight: 20, alignSelf: "center" }}
                      ></img>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "70%", flexWrap: "wrap" }}>
                        <div>
                          <div>SIM name: {item.sim_name}</div>
                          <div>Country: {countriesData.findByIso2(item.country.toUpperCase())?.name}</div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", flexWrap: "wrap" }}>
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: 5 }}>
                            {<input type="checkbox" checked={item.worldwide} onChange={() => {
                              console.log("WORW")
                              fetch(global.SERVIP + 'update-sender-worldwide', {
                                method: "POST",
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': 'Bearer ' + token
                                },
                                body: JSON.stringify({ id: item.id, worldwide: !item.worldwide })
                              })
                                .then(response => response.json())
                                .then(async res => {
                                  if (!res.ok)
                                    return alert(res.message || "Error updating service")
                                  item.worldwide = !item.worldwide;
                                  skroldubina.value = window.scrollY;
                                  setSenders(new Array(...senders));
                                  alert("Service successfully updated");
                                })
                                .catch(e => {
                                  console.log(e);
                                  alert("Error updating service")
                                })
                            }}
                            />}<div style={{ fontSize: 16, color: "#000000", margin: 5 }}> Worldwide</div></div>
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: 5 }}>{
                            <input checked={item.safety} type="checkbox" onChange={() => {
                              fetch(global.SERVIP + 'update-sender-safety', {
                                method: "POST",
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': 'Bearer ' + token
                                },
                                body: JSON.stringify({ id: item.id, safety: !item.safety })
                              })
                                .then(response => response.json())
                                .then(async res => {
                                  if (!res.ok)
                                    return alert(res.message || "Error updating service")
                                  item.safety = !item.safety;
                                  skroldubina.value = window.scrollY;
                                  setSenders(new Array(...senders));
                                  alert("Service successfully updated");
                                })
                                .catch(e => {
                                  console.log(e);
                                  alert("Error updating service")
                                })
                            }}></input>}<div style={{ fontSize: 16, color: "#000000", margin: 5 }}> Safety</div></div>
                        </div>
                        <Button style={{ backgroundColor: '#b71c1c', color: "#fff" }} onClick={() => {
                          console.log("ID JE " + item.id)
                          fetch(global.SERVIP + 'remove-sender', {
                            method: "POST",
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer ' + token
                            },
                            body: JSON.stringify({ id: item.id })
                          })
                            .then(response => response.json())
                            .then(async res => {
                              console.log("RESP ")
                              if (!res.ok)
                                return alert("Error stopping the service")
                              senders.splice(index, 1)
                              skroldubina.value = window.scrollY;
                              setSenders(new Array(...senders));
                              alert("Successfully stopped the service");
                            })
                            .catch(e => {
                              alert("Error stopping the service")
                            })
                        }} >Remove</Button>
                      </div>
                    </div>
                  })}
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <div className="timeline-box">

                    <Title level={4}>FAQ </Title>
                    <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                    </Paragraph>
                    <div style={{ maxHeight: 600, overflow: 'auto' }}>
                      <Timeline
                        style={{ padding: 10 }}
                      >
                        {faq.map((question, index) => (
                          <Timeline.Item color={"blue"} key={index}>
                            <Title level={5}>{question.question}</Title>
                            <Text>{question.answer}</Text>
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

        </Main>
      </> : <div>Loading ...</div>
  )
}


export default SmsGateway;
