import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import {
  Layout,
  Menu,
  Button,
  Typography,
  Card,
  Form,
  Input,
  Checkbox,
} from "antd";
const SignUpForm = () => {

  const cookies = new Cookies();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatedPassword, setRepeatedPassword] = useState("")
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [terms, setTerms] = useState(false)
  const navigate = useNavigate();

  const routeChange = () => {
    let path = `/login`;
    navigate(path);
  }


  function validateEmail(email) {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.match(emailRegex);
  }


  async function register(event) {
    if (password == "") {
      if (password == "")
        alert("Please enter a password")
      return;
    }
    if (!validateEmail(email)) {
      alert("Please enter a valid email address")
      return;
    }
    if (!terms) {
      alert("Please agree to the terms and conditions before continuing.")
      return;
    }
    console.log("Entering")
    await fetch(global.SERVIP + 'users/register', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then(response => {
        console.log("desilo se")
        console.log(response)
        if (response.ok) {
          response.json().then(res => {
            console.log(res)
            if (res.status == 400)
              alert('User alredy exists')
            else if (res.status == 401)
              alert('Verification email has alredy been sent')
            else {
              alert('Success, verification email has been sent to ' + email + ". It may take up to 5 minutes for it to be delivered. Please, also check the spam folder. ");
              routeChange()
            }
          })
        }
        else
          return
      })


  }

  useEffect(() => {

    if (cookies.get('token') != null && cookies.get('token').length > 0) {
      navigate('/dashboard')
    }

    if (cookies.get("functionality_cookies") === 'true')
      if (cookies.get('tema') == null) {
        cookies.set('tema', 0, { path: '/', expires: new Date(Date.now() + 365 * 86400 * 1000) })
      }

    if (window.google) {
      if (global.iskoristio) return;
      global.iskoristio = true;
      window.google.accounts.id.initialize({
        client_id: "633428894008-44t6dh44j7bddob5bb0v77lp3eglp2h5.apps.googleusercontent.com",
        callback: (response) => {
          fetch(global.SERVIP + "users/googleSignIn", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({ credential: response.credential }),
          })
            .then(res => res.json())
            .then((data) => {
              if (data?.ok && data?.token) {
                cookies.set('token', data.token, { path: '/', expires: (new Date(new Date().getTime() + 100000000000)) });

                if (cookies.get("functionality_cookies") === 'true')
                  if (!cookies.get('tema') || cookies.get('tema') == 0)
                    navigate("/api");
                  else
                    navigate("/outreach")
                else window.location.replace("/signup");
              }
              else if (data?.message) {
                alert(data.message)
              }
              else {
                alert("Error!")
              }
            })
            .catch(() => {
              alert("Error!")
            });

        }
      })
      window.google.accounts.id.renderButton(document.getElementById("gsignup"), {
        theme: "outline",
        text: "continue_with",
        size: 'large',
        shape: "rectangular",
        width: "220px"
      });

    }
  }, [])

  const handleSignUp = (event) => {
    event.preventDefault()
    event.target.reset()
    toast("Sign Up Successful")

    setTimeout(() => {
      navigate('/');
    }, 1500);
  }

  return (
    <>
      <div className="sc-login-section sc-pt-120">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">

              <div id="gsignup" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10 }}>

              </div>
              <div className="sc-login-box sc-register-box">
                <div className="login-form register-form">
                  <h4 className="text-center">Easily Create Your Account</h4>
                  <div className="row">

                    <div className="col-lg-6">
                      <label className="login-label">Email</label>
                      <input onChange={(e) => setEmail(e.target.value)} className="from-control" type="email" id="s-email" name="s-email" placeholder="Your Email" autoComplete="on" required />
                    </div>

                    <div className="col-lg-6">
                      <label className="login-label">Password</label>
                      <input onChange={(e) => setPassword(e.target.value)} className="from-control" type="password" id="s-password" name="s-password" placeholder="********" autoComplete="off" required />
                    </div>
                  </div>
                </div>

                <div className="register-account d-flex align-items-center justify-content-between">
                  <button className="submit-button" onClick={() => register()}>
                    <input type="submit" value="Create an Account" />
                  </button>
                  <div className="register-signin">Already Have an Account?<a href="/login#"> Login</a></div>
                </div>
              </div>
              <Checkbox
                value={terms}
                onClick={() => { setTerms(!terms) }}
              >
                I agree the{" "}
                <Link to='/tos' className="font-bold text-dark">
                  Terms and Conditions
                </Link>
              </Checkbox>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpForm;