/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";
import {React, useEffect, useState} from "react";

function LineChart(props) {
  const { Title, Paragraph } = Typography;
  const [messagesPerCounty, setMessagesPerCounty] = useState([]);
  useEffect(() => { 
    const messagesSortedPerCountry = {};
    props.messages.forEach(message => {
      if(!messagesSortedPerCountry[message.country])
      {
        messagesSortedPerCountry[message.country] = []
      }
      messagesSortedPerCountry[message.country].push(message)
    })
    console.log(props.messages)
    const data = []
    Object.entries(messagesSortedPerCountry).forEach(message =>{
      const result = Array(12).fill(0);
      message[1].forEach(obj => {
        const month = new Date(obj.createdAt).getMonth();
        result[month] += 1
      });
      data.push({name:message[0],data:result})
    })
    setMessagesPerCounty(data)
     
  },[props.messages])
  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Messages per country</Title>
          <Paragraph className="lastweek">
          This chart allow you to track where your users are coming from.
        </Paragraph>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={messagesPerCounty}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
