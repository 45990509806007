import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import featureImg7 from '../../../assets/images/feature/feature_img7.png'
import CodeSippetTabs from '../../../../../../components/CodeSnippetTabs'
import {languages} from '../../../../../../data/snippets'

const SmsApiService = () => {
    return (
        <>
            <section className="sc-service-section-area sc-pt-210 sc-md-pt-90 sc-pb-140 sc-md-pb-50 sc-pb-30 container-fluid2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="sc-heading-area text-center sc-mb-60">
                                <h2 className="heading-title">Discover Our Scalable and User-Friendly SMS Text Messaging API.</h2>
                            </div>
                        </div>
                    </div>


            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center" style={{display: 'flex', justifyContent: 'center'}}>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">How does it work?</span>
                                        <h2 className="heading-title sc-mb-25">Effortlessly dispatch texts from programming scripts utilizing our user-friendly Send SMS API.</h2>
                                        <div className="description sc-mb-25">
                                        Boost Your Communication Potential with Our Powerful SMS Gateway API: Enjoy Unsurpassed Language Compatibility, Expandability, Attractive Rates, and Simple Integration. Our wide array of language-centric bundles enable developers to smoothly integrate SMS Messaging API into their applications. Unleash boundless scalability, economically viable solutions, and an intuitive user journey. Amplify your communication capabilities with our enterprise-level Text Message API.                                        </div>
                                    </div>
                                    <div className="feature_check" style={{display: 'flex', justifyContent: 'center',alignItems: 'center',}}>
                                        <ul style={{listStyle:'none', padding:0}}>
                                            <li><i className="ri-checkbox-circle-fill" ></i>Easy Integration: Seamlessly integrate our SMS API into any application, supporting multiple languages.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Scalable Performance: Ensure reliable message delivery with our highly scalable SMS API.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Competitive Pricing: Benefit from cost-effective plans tailored to your business, delivering affordability and value.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade left>
                        <div className="row align-items-center" style={{backgroundColor: '#101621', borderRadius:10, padding:10}}>
                        <CodeSippetTabs
                languages={languages}
              />
                    </div>
                        </Fade>
                    </div>
                </div>
            </section>
            <div className="btn-area sc-mt-40" style={{display: 'flex', flexDirection: 'column',justifyContent: 'center',alignItems: 'center'}}>
                                        <a className="primary-btn" href="/signup#" style={{width: 200}}><span>Get Started</span></a>
                                    </div>

                </div>
            </section>
        </>
    );
};

export default SmsApiService;