import React, { useState, useEffect } from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import clientImg from '../../../assets/images/icons/client-img.png'
import clientImg1 from '../../../assets/images/icons/client_img1.png'
import clientImg3 from '../../../assets/images/icons/client_img.png'
import clientImg2 from '../../../assets/images/icons/client_img2.png'
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Statistic,
    Button,
    List,
    Descriptions,
    Avatar,
    Typography,
  
  } from "antd";
  const sendYourself = [
    {
      title:'Free',
      amount:500,
      price:0
    },
    {
      title:'Basic',
      amount:10000,
      price:10
    },
    {
      title:'Pro',
      amount:100000,
      price:50
    },
    {
      title:'Ultra',
      amount:500000,
      price:100
    },
    {
      title:'Unlimited',
      amount:10000000000,
      price:200
    },
    
  ]
const PricingComponent = () => {
    let [priceToggle, setPriceToggle] = useState(true)
    const cookies = new Cookies();


    const [countries, setCountries] = useState([]);
    const [curPacages, setCurPacages] = useState([]);
    const [curRegion, setCurRegion] = useState('USA Canada');
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/signup`;
        navigate(path);
      }
    
      useEffect(() => {
        if (cookies.get('token') != null && cookies.get('token').length > 0) {
          navigate('/dashboard')
        }
    
        const requestCountries = async () => {
          console.log(global.SERVIP)
          await fetch(global.SERVIP + 'countries', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          })
            .then(response => {
              if (response.ok) {
                response.json().then(res => {
                  console.log(res)
                  setCountries(res)
                  setCurRegion(res[0].name)
                  getPacagesFromCounty(res[0])
                })
              }
              else
                alert('Error')
            })
        }
        requestCountries()
      }, []);
    
      async function getPacagesFromCounty(item) {
        setCurRegion(item.name)
        console.log(global.SERVIP)
        await fetch(global.SERVIP + 'countries/' + item.id + '/packages', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
          .then(response => {
            if (response.ok) {
              response.json().then(res => {
                setCurPacages(res);
                console.log(curPacages)
              })
            }
            else
              alert('Error')
          })
      }
      const CustomRightArrow = ({ onClick }) => {
        return <button className='commonArrow arrowRight' style={{display:'flex', justifyContent: 'center', alignItems: 'center',width:50,height:50}}  onClick={() => onClick()}><i className="ri-arrow-right-line"></i></button>;
    };

    const CustomLeftArrow = ({ onClick }) => {
        return <button className='commonArrow arrowLeft' style={{display:'flex', justifyContent: 'center', alignItems: 'center',width:50,height:50}} onClick={() => onClick()}><i className="ri-arrow-left-line"></i></button>;
    };

    return (
        <>
            <section className="sc-price-area third-bg sc-pt-125 sc-md-pt-65 sc-pb-140 sc-md-pb-80 sc-sm-mb-40" style={{backgroundColor:'white'}}>
                <div className="container">
                    <div className="sc-heading-area text-black sc-mb-40 sc-md-mb-30 text-center">
                        <h2 className="title sc-mb-25 text-black" style={{color:'black'}}>Choose your plan based on your needs</h2>
                        <span className="sub_title text-black" style={{color:'black'}}>We have 3 pricing options, choose the one that best fits your needs</span>.
                        <br/>
                        <a>If you have multiple products, for example package, credits and your own gateway, we will use the least expensive option to send a message based on the recipients phone number, so you don't need to worry about that.</a>
                    </div>
                    <div className="sc-heading-area text-black sc-mb-40 sc-md-mb-30 text-center">
                        <h2 className="title sc-mb-25 text-black" style={{color:'black'}}>Pay as you go</h2>
                        <a className="sub_title text-black"  href="/pay-as-you-go" style={{color:'black'}}>Click to see price per message for each country</a>
                        <div className="sc-primary-btn" style={{width:"100%", display: "flex", justifyContent:'center', alignItems: 'center', color:'black'}}>
                                 <Link className="secondary-btn" to="/pay-as-you-go" style={{maxWidth:200,margin:5}}><span>Prices</span></Link>
                        </div>
                    </div>
                    <div className="sc-heading-area text-black sc-mb-40 sc-md-mb-30 text-center">
                        <span className="sub_title text-white" style={{color:'black'}}>Or</span>
                    </div>
                    <div className="sc-heading-area text-black sc-mb-40 sc-md-mb-30 text-center">
                        <h2 className="title sc-mb-25 text-black">Packages</h2>
                        <span className="sub_title text-black" style={{color:'black'}}>If you plan on sending large amount of messages to a certain region, this is the plan for you.</span>
                    </div>
                    <div className="pricing-selector">

                    <Row style={{ marginBottom: 10, display: 'flex', justifyContent: 'center', padding: 10 }}>
              {countries.map((country, index) =>
                <button type="button" class="ant-btn css-dev-only-do-not-override-1n7nwfa"
                  onClick={async () => await getPacagesFromCounty(country)}
                  style={{ margin: 10, color: 'white', backgroundColor: country.name == curRegion ? "black" : "white", }}>
                  <h4 style={{ color: country.name == curRegion ? "white" : '#6A76EC', fontWeight:'bold' }} class="ant-list-item-meta-title">{country.name}</h4>
                </button>
              )}
            </Row>
                    </div>
                    <section >
                <div className="container">
                    <div className="client-testimonial-wrap">


                        <div className='client-carousel-wrapper'>
                            <Carousel className="client-carousel"
                                infinite={true}
                                draggable={true}
                                arrows={true}
                                pauseOnHover={true}
                                slidesToSlide={1}
                                swipeable={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                customRightArrow={<CustomRightArrow />}
                                customLeftArrow={<CustomLeftArrow />}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 992
                                        },
                                        items: 3,
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 991,
                                            min: 577
                                        },
                                        items: 2,
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 576,
                                            min: 1
                                        },
                                        items: 1,
                                    }
                                }}>
                                      {curPacages.map((item, index) => (
 <div className="col-lg-4 col-md-6 sc-md-mb-30" data-aos="slide-up" data-aos-duration="800" data-aos-delay="100" style={{width:'100%',}}>
                                    <div className="sc-price-box" style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center',backgroundColor:'#f5f5f5'}}>
                                        <div className="price-item">
                                            <h4 className="pric-title">{item.messageLimit + ' messages'}</h4>
                                            <div className="price-active third_color">{Math.round(item.messageLimit * item.pricePerMessageUSD)} $</div>
                                        </div>
                                        <div className="perfect-title">{item.pricePerMessageUSD + ' $ per message'}</div>
                                        <div className="price-list">
                                        <i className="ri-checkbox-circle-fill" style={{color:"#5941a9"}}></i>
                                                <span>{curRegion}</span>
                                        </div>
                                        <div  >
                                            <a className="secondary-btn" href="/signup#"><span>Buy</span></a>
                                        </div>
                                    </div>
                                </div>
                              ))}
                             
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
            <div style={{marginTop:'10%'}}>
                    <div className="sc-heading-area text-black sc-mb-40 sc-md-mb-30 text-center">
                        <h2 className="title sc-mb-25 text-black">Mass SMS pool (Use your own numbers)</h2>
                        <span className="sub_title text-black" style={{color:'black'}}>If you plan on sending large amount of messages to a certain region, this is the plan for you.</span>
                    </div>
            <section >
                <div className="container">
                    <div className="client-testimonial-wrap">


                        <div className='client-carousel-wrapper'>
                            <Carousel className="client-carousel"
                                infinite={true}
                                draggable={true}
                                arrows={true}
                                pauseOnHover={true}
                                slidesToSlide={1}
                                swipeable={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                customRightArrow={<CustomRightArrow />}
                                customLeftArrow={<CustomLeftArrow />}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 992
                                        },
                                        items: 3,
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 991,
                                            min: 577
                                        },
                                        items: 2,
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 576,
                                            min: 1
                                        },
                                        items: 1,
                                    }
                                }}>
                                      {sendYourself.map((item, index) => (
 <div className="col-lg-4 col-md-6 sc-md-mb-30" data-aos="slide-up" data-aos-duration="800" data-aos-delay="100" style={{width:'100%',}}>
                                    <div className="sc-price-box" style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center',backgroundColor:'#f5f5f5'}}>
                                        <div className="price-item">
                                            <h4 className="pric-title">{item.price} $ per month</h4>
                                            <div className="price-active third_color">{item.title}</div>
                                        </div>
                                        <div className="perfect-title">{item.amount} messages per month</div>
                                       
                                        <div  >
                                            <a className="secondary-btn" href="/signup#"><span>Buy</span></a>
                                        </div>
                                    </div>
                                </div>
                              ))}
                             
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
            </div>
                </div>
            </section>
        </>
    );
};

export default PricingComponent;