import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logoDark from '../../assets/images/tfLogo.png'
import manuImg from '../../assets/images/list.png'

const OffCanvasMenu = (props) => {
    const [iconToggle1, setIconToggle1] = useState(true)
    const [iconToggle2, setIconToggle2] = useState(true)
    const [iconToggle3, setIconToggle3] = useState(true)

    const { parentMenu } = props;
    const location = useLocation();

    return (
        <>
            <div className='offcanvas-area'>
                <Link data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><img src = {manuImg} className="ri-menu-line" style={{width:40,height:40}}></img></Link>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header" style={{display:'flex', justifyContent: 'center',alignItems: 'center'}}>
                        <Link to="/"><img src={logoDark} style={{aspectRatio:"3/1", width:"45%", objectFit:'contain',display:'flex', alignSelf:'flex-end'}} alt="DarkLogo" /></Link>
                        <button type="button" className="offcanvasClose" data-bs-dismiss="offcanvas" aria-label="Close" style={{display:'flex', justifyContent:"center", alignItems: 'center'}}><i className="ri-close-fill"></i></button>
                    </div>
                    <div className="offcanvas-body">
                        <nav className="navbar navbar-light">
                            <div className="w-100">
                                <div className="collapse navbar-collapse show" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/' ? 'nav-link active' : 'nav-link'} to="/" id="navbarDropdown" >
                                                Home
                                            </Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className={parentMenu === 'pages' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setIconToggle2(!iconToggle2)}>
                                                Services
                                                {iconToggle2 ? <i className="ri-add-line"></i> : <i className="ri-subtract-line"></i>}
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li>
                                                    <Link className={location.pathname === '/smsapi' ? 'dropdown-item active' : 'dropdown-item'} to="/smsapi">SMS API</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/smsverification' ? 'dropdown-item active' : 'dropdown-item'} to="/smsverification">Verification API</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/bulksms' ? 'dropdown-item active' : 'dropdown-item'} to="/bulksms">No-Code outreach</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/useyournumbers' ? 'dropdown-item active' : 'dropdown-item'} to="/useyournumbers">Use your own numbers</Link>
                                                </li>
                                                <li className="nav-item">
                                            <Link className={location.pathname === '/marketplace' ? 'dropdown-item active' : 'dropdown-item'} to="/marketplace">Marketplace</Link>
                                        </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a className={location.pathname === '/login' ? 'nav-link active' : 'nav-link'} href="/login">Login</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={location.pathname === '/signup' ? 'nav-link active' : 'nav-link'} href="/signup">Sign up</a>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/contact' ? 'nav-link active' : 'nav-link'} to="/contact">Contact</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </nav>
                        <div className="offcanvas-icon-list">
                            <ul>
                            <li>
                                    <a href="https://linkedin.com/company/textflow"><i className="ri-linkedin-fill"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/watch?v=_oYDBdezykg&t=30s"><i className="ri-video-fill"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=100090463583440&locale=hi_IN&paipv=0&eav=AfZ3eP7KQjqNIjebYkaWSP_IaCYP_alGcJBqp9rvfUhQycoDNepMkYmBJ5SMkW6uIGY"><i className="ri-facebook-fill"></i></a>
                                </li>                            
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OffCanvasMenu;