const csCode = `using TextFlow;

TextFlowClient client = new TextFlowClient ("YOUR_API_KEY");

// Sending an SMS in one line
client.SendSMS("Hello, world!", "+11234567890");

// OTP Verification
// User has sent his phone number for verification
var resultSMS = await client
    .SendVerificationSMS ("+11234567890", SERVICE_NAME, SECONDS);

// Show him the code submission form
// We will handle the verification code ourselves

// The user has submitted the code
var resultCode = await client.VerifyCode("+11234567890", "USER_ENTERED_CODE");
// if resultCode.Valid is true, then the phone number is verified.`;

export default csCode;