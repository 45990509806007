import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);
  const cookies = new Cookies();

  let navigate = useNavigate();

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }
  function navigateWithData() {
    if (cookies.get("functionality_cookies") === 'true')
      cookies.set('tema', 0, { path: '/', expires: new Date(Date.now() + 365 * 86400 * 1000) })
    navigate('/')
  }
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" style={{ color: 'white' }}>
              Welcome to <span className="text-color-primary">TextFlow Marketing</span>
            </h1>
            <div className="container-xm" style={{ color: 'white' }}>

              <h2 className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400" style={{ fontSize: "1em" }}>
                Only software you'll need to run SMS marketing and outreach campaigns with no code, or implement the SMS and Verification API's yourself.
              </h2>
              <div className="reveal-from-bottom" data-reveal-delay="600" style={{ marginTop: '1em', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: 40, alignItems: 'center' }}>
                <Button tag="a" color="primary"
                  onClick={() => navigateWithData()}
                  style={{ marginTop: 20, marginBottom: 80, color: 'white', backgroundColor: '#6163FF', fontSize: 15, maxWidth: 500, width: "70%", fontFamily: 'Raleway', borderRadius: 20 }}
                >
                  Switch to API section</Button>
                <h2 className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400" style={{ fontSize: "1em" }}>
                  Marketing section
                </h2>                  <h2 className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400" style={{ fontSize: "0.5em", marginBottom: 10 }}>
                  A new product in our portfolio, try it out for free.
                </h2>
                <ButtonGroup style={{ color: 'white', marginTop: 10 }}>
                  <Button onClick={() => { navigate("/signup") }} tag="a" color="primary" style={{ width: 250 }} >
                    Get started
                  </Button>

                </ButtonGroup>
              </div>
            </div>
          </div>

          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;