import { useState, useEffect } from "react";

import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Progress,
    Upload,
    message,
    Button,
    Timeline,
    Radio,
    Input,
} from "antd";
import {
    SearchOutlined,
    ToTopOutlined,
    MenuUnfoldOutlined,
    RightOutlined,
    MessageFilled,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Main from "../components/layout/Main";
import Cookies from 'universal-cookie';
import CodeSnippetTabs from "../../../components/CodeSnippetTabs";
import { useNavigate, useLocation } from "react-router-dom";
import { languages } from "../../../data/snippets";
import "../assets/styles/sms.css"
import CookieConsent from "../../../components/CookieConsent";

function formatDate(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();

    function formatTime(date) {
        return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    }

    function isToday(someDate, today) {
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear();
    }

    function isThisYear(someDate, today) {
        return someDate.getFullYear() === today.getFullYear();
    }

    if (isToday(date, now)) {
        return formatTime(date);
    } else if (isThisYear(date, now)) {
        return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${formatTime(date)}`;
    } else {
        return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()} ${formatTime(date)}`;
    }
}

function Sms() {
    const location = useLocation()
    const { Title, Text } = Typography;

    const [loading, setLoading] = useState(location.state ? true : false);
    const [search, setSearch] = useState("");
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [opened, setOpened] = useState("");
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState([])
    const [apiKeys, setApiKeys] = useState([])

    const cookies = new Cookies();
    const token = cookies.get('token')

    useEffect(() => {
        if (!opened) return;

        fetch(global.SERVIP + 'get-conversation-j', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({ phone_number: opened })
        })
            .then(response => {
                if (!response.ok)
                    return alert('Error')
                response.json().then(res => {
                    if (!res.ok)
                        return alert('Error')
                    setMessages(res.messages);
                })
            })
            .catch(e => {
                alert("Server error")
            })
        const int1 = setInterval(() => {
            console.log("INTERVAL " + opened)
            if (!opened) return;

            fetch(global.SERVIP + 'get-conversation-j', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ phone_number: opened })
            })
                .then(response => {
                    if (!response.ok)
                        return alert('Error')
                    response.json().then(res => {
                        if (!res.ok)
                            return alert('Error')
                        setMessages(res.messages);
                    })
                })
                .catch(e => {
                    alert("Server error")
                })
        }, 60000)
        return () => {
            clearInterval(int1);
        }
    }, [opened])
    useEffect(() => {
        fetch(global.SERVIP + 'get-conversations-j', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then(response => {
                if (!response.ok)
                    return alert('Error')
                response.json().then(res => {
                    if (!res.ok)
                        return alert('Error')
                    setPhoneNumbers(res.phone_numbers);

                    fetch(global.SERVIP + 'user/apikeys', {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                    })
                        .then(response => {
                            if (response.ok) {
                                response.json().then(res => {
                                    setApiKeys(res)
                                    if (loading)
                                        setLoading(false);
                                })
                            }
                            else
                                alert('Error')
                        })
                        .catch(e => {
                        })
                    setLoading(false);
                })
            })
            .catch(e => {
                alert("Server error")
            })
    }, []);

    return (
        (!loading) ?
            <>
                <Main>
                    <CookieConsent></CookieConsent>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16} className="mb-24">
                            <Card bordered={false} className="criclebox cardbody h-full" style={{}} >
                                <div style={{ display: "flex", flexDirection: "column", padding: 20, height: "100%", width: "100%" }}>
                                    <h3 style={{ flex: "0 0 auto" }}>{opened}</h3>
                                    <div style={{ flex: "1 1 auto", overflowY: "auto", height: "60vh" }}>
                                        {messages.map(message => <div><div style={{ marginTop: 18, marginLeft: 18, marginRight: 18, display: 'flex', flexDirection: 'row', justifyContent: message.sent ? "flex-end" : "flex-start", maxWidth: "100%" }}>
                                            <div style={{ display: "inline-block", backgroundColor: message.sent ? "blue" : "lightgray", color: message.sent ? "white" : "black", padding: 10, borderRadius: 15, maxWidth: '60%' }}>{message.content}</div>

                                        </div><div style={{ marginLeft: 18, marginRight: 18, display: 'flex', flexDirection: 'row', justifyContent: message.sent ? "flex-end" : "flex-start", maxWidth: "100%" }}>{formatDate(message.createdAt)}</div></div>)}
                                    </div>
                                    {<div style={{ display: "flex", flexDirection: "row", width: '100%' }}>
                                        <Input type="text" placeholder="Send message..." value={messageText} onChange={(e) => setMessageText(e.target.value)}></Input>
                                        <Button onClick={() => {
                                            fetch(global.SERVIP + 'send-sms', {
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json',
                                                    'Content-Type': 'application/json',
                                                    'Authorization': 'Bearer ' + apiKeys[0].value
                                                },
                                                body: JSON.stringify({ phone_number: opened, text: messageText })
                                            })
                                                .then(response => response.json())
                                                .then(json => {
                                                    if (json.ok) {
                                                        messages.push({ content: json.data.content, createdAt: new Date().toISOString(), sent: true })
                                                        setMessages(new Array(...messages));
                                                    }
                                                    else {
                                                        alert("Error sending a message");
                                                    }
                                                })
                                                .catch(e => {
                                                    alert("Server error")
                                                })
                                            setMessageText("");
                                        }} title="Dugme">Send</Button>
                                    </div>}
                                </div>


                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="mb-24">
                            <Card bordered={false} className="criclebox cardbody h-full">
                                <div className="respklasa" style={{ display: "flex", width: "100%", padding: 10 }}>
                                    <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
                                        <Input type="text" placeholder="Search for a number..." value={search} style={{ width: "90%" }} onChange={(e) => setSearch(e.target.value)}></Input>

                                        <div style={{ overflowY: "auto", flex: "1 1 auto" }}>
                                            {phoneNumbers.length != 0 ? phoneNumbers.filter(e => e.phone_number.includes(search)).map(e => {
                                                let date = new Date(e.latest);
                                                return <div onClick={() => setOpened(e.phone_number)} key={e.phone_number + e.latest} style={{ backgroundColor: "grey", margin: 5, color: "white", borderRadius: 5, padding: 5, fontSize: 'larger', textAlign: "center", cursor: "pointer" }}>
                                                    <Timeline.Item color={"blue"} >
                                                        <Title level={5}>To : {e.phone_number}</Title>
                                                        <Text>{date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()} {date.getHours()}:{date.getMinutes()}</Text>
                                                    </Timeline.Item>
                                                </div>
                                            }) : <a style={{
                                                fontWeight: 'bold',
                                            }}>No messages sent</a>}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Main >
            </> : <div>Loading ...</div>
    )
}


export default Sms;