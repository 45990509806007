import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import './TextLink.css'
import InterestForm from './InterestForm';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
const TextLinkService = () => {
    const [email, setEmail] = useState('');
    const [type, setType] = useState(''); // 'buyer' or 'seller'
    const navigate = useNavigate()
    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log({ email, type }); // This logs the submitted data. Connect this to your backend/API.
    };
    global.bannerClosed = true
    return (
        <>
            <section className="sc-service-section-area sc-pt-210 sc-md-pt-90 sc-pb-140 sc-md-pb-50 sc-pb-30 container-fluid2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="sc-heading-area text-center sc-mb-60">
                                <h2 className="heading-title">Introducing TextLink: Your Ultimate SMS Marketplace</h2>
                                <p style={{fontSize:'1.1em'}}>Connect with sellers and buyers of SMS services seamlessly. Buy and sell SMS services for all countries. Filter by content allowed (links, phone numbers, promotional) and much more...</p>
                                <Button style={{ fontSize: '1.1em' }} onClick={() => { window.open('https://textlinksms.com', '_blank'); }}>Check it out!</Button>
                            </div>
                        </div>
                    </div>

                    <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                        <div className="container">
                            <div className="row align-items-center" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Fade right>
                                    <div className="col-lg-6">
                                        <div className="sc-feature-item">
                                            <div className="sc-heading-area sc-mb-20">
                                                <span className="sc-sub-title primary-color">For Sellers</span>
                                                <h2 className="heading-title sc-mb-25">Two Ways to Sell</h2>
                                                <div className="description sc-mb-25">
                                                    1. Advertise your own SMS API solution or website.<br />
                                                    2. Install our app on your Android device, set up an SMS gateway, and earn up to $1500 per month per number.
                                                </div>
                                                <p style={{fontSize:'1em'}}>Set which countries you send to (US,Canada,UK,China,...) and which content you allow through your gateway.</p>
                                                <p style={{fontSize:'1em'}}>Anyone with an android phone and a SIM card can become a seller and earn passive income. Just complete the setup, choose how you want to be paid and that's it, you dont have to do anything else.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                                <Fade right>
                                  
                                            <div className="sc-heading-area sc-mb-20">
                                                <span className="sc-sub-title primary-color">For Buyers</span>
                                                <h2 className="heading-title sc-mb-25">Cheapest SMS services</h2>
                                                <div className="description sc-mb-25">
                                                    Just select which countries you want to send to, and what type of content and thats it.<br />
                                                   Prices are much cheaper than if you were using services like Twilio or Vonage. (as low as 0.001 per message)
                                                </div>
                                             
                                            </div>
                                      
                                </Fade>
                            </div>
                        </div>
                        <Button style={{ fontSize: '1.4em' }} onClick={() => { window.open('https://textlinksms.com/sellsms', '_blank'); }}>Become a seller!</Button>

                    </section>

                    {/* Interest Form */}



                </div>
            </section>
        </>
    );
};

export default TextLinkService;
