const pythonCode = `import textflow as tf
tf.useKey("YOUR_API_KEY")

# Sending an SMS in one line
tf.sendSMS(recipient="+11234567890", text="Dummy text")

# OTP Verification
# User has sent his phone number for verification
resultSMS = tf.sendVerificationSMS ("+11234567890", service_name, seconds)

# Show him the code submission form
# We will handle the verification code ourselves

# The user has submitted the code
resultCode = tf.verifyCode("+11234567890", user_entered_code)
# if resultCode.valid is True, then the phone number is verified. `;

export default pythonCode;