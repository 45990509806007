import React from 'react';
import { Fade } from 'react-reveal';
import businessImg from '../../../assets/images/banner/business_img.png'
import shapeImage from '../../../assets/images/banner/shape_image.png'
import CodeSippetTabs from '../../../../../../components/CodeSnippetTabs'

import {languages} from '../../../../../../data/snippets'

const Business = () => {
    return (
        <>
            <section className="sc-business-section-area dark-bg-color sc-pt-140 sc-md-pt-70 sc-pb-140 sc-md-pb-80">
            <div className="sc-heading-area sc-mb-30">
                                        <h2 style={{color:'white'}} className="heading-title">A package for every language</h2>
                                    </div>
                <div className="container"> 
                <Fade left>

                    <div className="row align-items-center" style={{backgroundColor: '#101621', borderRadius:10, padding:10}}>
                        <CodeSippetTabs
                languages={languages}
                light
              />
                    </div>
                    </Fade>

                </div>
            </section>
        </>
    );
};

export default Business;