import { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Input,
} from "antd";
import ava5 from "../user/assets/images/logo-jira.svg";
var countriesData = require('country-list-js');

function getCountrieDataFromISO2(iso2) {
  return countriesData.findByIso2(iso2)
}

function PerCountryPricing() {
  const { Title, Text } = Typography;

  const [prices, setPrices] = useState([])
  const [query, setQuery] = useState("")
  useEffect(() => {
    fetch(global.SERVIP + 'pay-as-you-go', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.ok) {
          let arr = [];
          for (let i in response.data) {
            let country_data = getCountrieDataFromISO2(i);
            console.log(i);
            arr.push({
              iso2: i,
              price: response.data[i],
            })
            if (country_data)
              arr[arr.length - 1].name = country_data.name;
            else {
              arr.pop();
            }
            //arr[arr.length - 1].name = i;
          }
          console.log(arr)
          setPrices(arr);
        }
      })
      .catch(e => {
        console.log("KECIIIIC")
        console.log(e);
      })
  }, []);
  return (
    <div>
    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Col xs={24} sm={24} md={20} lg={18} xl={16} className="mb-24">
        <div style={{ width: '100%', backgroundColor: 'white', position: 'fixed', zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', left: 0, height: 80 }}>
          <Input style={{ width: '90%' }} placeholder={"Search for countries here"} type={"search"} value={query} onChange={(e) => setQuery(e.target.value)} />
        </div>
        <Card bordered={false} style={{ marginTop: 90 }}>
          <div className="project-ant">
            <div>
              <Title level={5}>Pricing</Title>
            </div>
          </div>
          <div className="ant-list-box table-responsive" style={{ overflowY: 'auto' }}>
            <div>
              <table className="width-100" style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th>Flag</th>
                    <th>Country</th>
                    <th>Price per message</th>
                  </tr>
                </thead>
                <tbody>
                  {prices.sort((a, b) => (a.price - b.price))
                    .filter(e => (e.name.replace(/\s/g, '').toLocaleUpperCase().includes(query.replace(/\s/g, '').toLocaleUpperCase()))
                      || e.iso2.toLocaleUpperCase().includes(query.replace(/\s/g, '').toLocaleUpperCase()))
                    .map((country, index) => (
                      <tr key={country.iso2} onClick={() => { }}>
                        <td>
                          <img
                            alt={country.name}
                            style={{ maxWidth: '100%', height: 'auto' }}
                            src={"http://purecatamphetamine.github.io/country-flag-icons/3x2/" + country.iso2.toLocaleUpperCase() + ".svg"} />
                        </td>
                        <td>
                          <span style={{ fontSize: '1rem' }}>
                            <br />
                            <div style={{ minWidth: '50%', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                              {country.name}
                            </div>
                          </span>
                        </td>
                        <td>
                          <span>
                            <br />{parseFloat(country.price)} $
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  </div>
  
  )
}

export default PerCountryPricing