import { read, utils } from 'xlsx';
import parsePhoneNumber from 'libphonenumber-js'

function ImportSpreadSheetButton({ callback, callfront, component }) {
    return <div onClick={() => {
        let input = document.getElementById("inputexcelid824");
        input.click();
    }}>
        {component}
        <form id='inputexcelformica824'>
            <input id='inputexcelid824' style={{ display: "none" }} type="file" accept=".xlsx, .xls, .csv, .txt" onChange={(event) => {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onload = function (event) {
                    const data = event.target.result;
                    const workbook = read(data, { type: 'binary' });
                    let arr = {};
                    for (let i in workbook.Sheets) {
                        let worksheet = workbook.Sheets[i];
                        let fieldValues = utils.sheet_to_json(worksheet);
                        for (let j = 0; j < fieldValues.length; j++) {
                            for (let k in fieldValues[j]) {
                                let kandidati = [k, "+" + k, fieldValues[j][k], "+" + fieldValues[j][k]]
                                for (let kand in kandidati) {
                                    let s = kandidati[kand];
                                    try {
                                        let ob = parsePhoneNumber(s);
                                        if (!ob || !ob.isValid() || !ob.isPossible() || !ob.country) {
                                            continue;
                                        }
                                        arr[ob.number] = true;
                                    } catch (e) {

                                    }
                                }
                            }
                        }
                    }
                    callback(Object.keys(arr));
                };
                let forma = document.getElementById("inputexcelformica824");
                reader.readAsBinaryString(file);
                forma.reset();
            }} />
        </form>
    </div>
}
export default ImportSpreadSheetButton;