import React, { useState } from 'react';
import axios from 'axios';
import countries from 'country-list-js';
var country_names = countries.names();

const InterestForm = () => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      console.error("Invalid email format");
      return;
    }
    setLoading(true);
    setSuccess(null);
    setError(null);

    try {
      const response = await axios.post(global.SERVIP + 'addToWaitlist', {
        email,
        role,
        country
      });
      setSuccess('Successfully added to the waitlist!');
    } catch (error) {
      setError('There was an error adding to the waitlist.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h3>Express Your Interest</h3>
      <form onSubmit={handleFormSubmit}>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label>
          I am a:
          <select value={role} onChange={(e) => setRole(e.target.value)} required>
            <option value="">Select...</option>
            <option value="seller">Seller</option>
            <option value="buyer">Buyer</option>
            <option value="both">Both</option>
          </select>
        </label>
        <label>
          To country (optional):
          <select value={country} onChange={(e) => setCountry(e.target.value)}>
            <option value="">Select...</option>
            {country_names.map(c => (
              <option key={c} value={c}>{c}</option>
            ))}
          </select>
        </label>
        <button className="primary-btn" type="submit" style={{color:'white'}}>Join the Waitlist</button>
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}

      </form>
    </>
  );
};

export default InterestForm;
