import { useState, useEffect } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Input,
} from "antd";
import {
  SearchOutlined,
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  MessageFilled,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Main from "../components/layout/Main";
import Cookies from 'universal-cookie';
import CodeSnippetTabs from "../../../components/CodeSnippetTabs";
import { useNavigate, useLocation } from "react-router-dom";
import { languages } from "../../../data/snippets";
import CookieConsent from "../../../components/CookieConsent";

const faq = [
  { question: "Do i need to put prefix for a number when sending a message?", answer: "Yes. Example US number: +11234567890" },
  { question: "Does the api use the least expensive package for sending the message?", answer: "Yes, when you make a request to our endpoint (with our package or classic http request), the creadits are deducted from the cheapest package (for that country)", },
  { question: "Do you have any video material on how to use the API", answer: "Yes, see out YouTube channel https://www.youtube.com/channel/UC0Zgv1UgKoAhCBZ07eozQaw for tutorials. When you register you will also see additional resources to help you with onboarding" },
  { question: "Do you offer special pricing for volumes", answer: "Yes, if you plan on larger usage than the packages we are offering provide, contact the sales department sales@textflow.me and someone will reach out shortly." },
  { question: "Do you have a way of tracking activity", answer: "Yes, once you register, you have access to the dashboard which displays all relevant information/statistics about your API usage. This helps you better track you users." }
]

function Api() {
  const location = useLocation()

  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(location.state ? true : false);
  const [apiKeys, setApiKeys] = useState([])
  const [curMessage, setCurMessage] = useState("")
  const [curNumber, setCurNumber] = useState(0)
  const [webhook, setWebhook] = useState("");
  const [secret, setSecret] = useState("");
  const cookies = new Cookies();
  const token = cookies.get('token')
  let navigate = useNavigate();

  async function generateApiKey() {
    await fetch(global.SERVIP + 'user/apikeys/generate', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    })
      .then(response => {
        console.log(response)
        if (response.ok) {
          response.json().then(res => {
            console.log(res)
            alert("Success")
          })
        }
        else
          alert('Error')
      })
      .catch(e => {
        alert("Server error")
      })
  }
  async function sendMessage() {
    await fetch(global.SERVIP + 'send-sms', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + apiKeys[0].value
      },
      body: JSON.stringify({ phone_number: curNumber, text: curMessage })
    })
      .then(response => response.json())
      .then(json => {
        alert(json?.message);
      })
      /*.then(response => {
        console.log(response)
        if (response.ok) {
          response.json().then(res => {
            console.log(res)
            alert(res.message)
          })
        }
        else {
          console.log(response);
          response.json().then(jsn => {
            alert("Gotovj")
            console.log(jsn);
          })
          //alert(response || 'Error')
        }
      })*/
      .catch(e => {
        alert("Server error")
      })
  }
  async function deleteApiKey(key) {
    await fetch(global.SERVIP + 'user/apikeys/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ value: key })
    })
      .then(response => {
        console.log(response)
        if (response.ok) {
          var updatedKeys = []
          for (let i = 0; i < apiKeys.length; i++) {
            if (apiKeys[i].value != key)
              updatedKeys.push(apiKeys[i])
          }
          setApiKeys(updatedKeys)
          alert("Success")
        }
        else
          alert('Error')
      })
      .catch(e => {
        alert("Server error")
      })
  }
  useEffect(() => {
    if (!token || token.length == 0) {
      navigate('/signup')
      return
    }
    fetch(global.SERVIP + 'get-webhook', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(res => {
          setWebhook(res.webhook);
          setSecret(res.webhook_secret);
        })
      }
    })
    async function requestApiKeys() {
      await fetch(global.SERVIP + 'user/apikeys', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              console.log(res)
              setApiKeys(res)
              if (loading)
                setLoading(false);
            })
          }
          else
            alert('Error')
        })
        .catch(e => {
          console.log("NEEE");
        })
    }
    requestApiKeys()
  }, [loading]);
  return (
    (!loading) ?
      <>
        <Main>
          <CookieConsent></CookieConsent>
          <div>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
                <Card bordered={false} className="criclebox cardbody h-full">
                  <div className="project-ant">
                    <div>
                      <Title level={5}>Send message</Title>
                      <Paragraph className="lastweek">
                        Try out our API
                      </Paragraph>
                    </div>
                    <div className="ant-filtertabs">

                    </div>
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Input
                      onChange={(e) => setCurNumber(e.target.value)}
                      placeholder="Type a number you want to send the message to"
                      style={{ marginBottom: '10px' }}
                    />
                    <Input
                      onChange={(e) => setCurMessage(e.target.value)}
                      placeholder="Type a message (120 characters max)"
                    />
                  </div>
                  <div className="uploadfile shadow-none">

                    <Button
                      onClick={async () => await sendMessage()}
                      className="ant-full-box"
                    >
                      <span className="click">Send</span>
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <div className="timeline-box">
                    <Title level={5}>Api keys</Title>
                    <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                    </Paragraph>
                    <div style={{ maxHeight: 300, overflow: 'auto' }}>
                      <Timeline
                        className="timelinelist"
                        style={{ padding: 10 }}
                      >
                        {apiKeys.map((key, index) => (
                          <Timeline.Item color={"blue"} key={index}>
                            <Title level={5}>{key.value}</Title>
                            <Text>{key.createdAt}</Text>

                            <Title onClick={async () => await deleteApiKey(key.value)} level={4}>Click to delete</Title>

                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                    <Button
                      onClick={async () => await generateApiKey()}
                      type="primary"
                      className="width-100"
                    >
                      Generate a new API key
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
                <Card bordered={false} className="criclebox cardbody h-full" style={{ padding: 20 }}>
                  <Title>Code snippets</Title>
                  <CodeSnippetTabs
                    languages={languages}
                    light
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <div className="timeline-box">
                    <Title level={4}>Webhook settings</Title>
                    <Text>We can send a HTTP POST request to your server when you receive a message. <a href="https://docs.textflow.me/receive">Learn more</a></Text> <br></br>
                    Endpoint: <Input placeholder="webhook" type="text" value={webhook} onChange={e => setWebhook(e.target.value)}></Input>
                    Secret:
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Input placeholder="secret" type="text" value={secret} onChange={e => setSecret(e.target.value)}></Input>
                      <Button onClick={() => {
                        let ranstr = "";
                        let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
                        for (let i = 0; i < 64; i++) {
                          ranstr += chars[Math.floor(Math.random() * chars.length)];
                        }
                        setSecret(ranstr);
                      }}>Generate</Button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: 10 }}>
                      <Button onClick={() => {
                        fetch(global.SERVIP + 'test-webhook', {
                          method: 'POST',
                          headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                          },
                          body: JSON.stringify({ webhook, secret })
                        }).then(response => {
                          if (response.ok) {
                            response.json().then(res => {
                              if (res.ok)
                                alert("Webhook is working!")
                              else
                                alert("Webhook is not working. It should return status code 200. ");
                            })
                          }
                          else {
                            alert("Webhook is not working. It should return status code 200. ");
                          }
                        }).catch(e => {
                          alert("Webhook is not working. It should return status code 200. ");
                        })
                      }}>Test webhook</Button>
                      <Button onClick={() => {
                        fetch(global.SERVIP + 'update-webhook', {
                          method: 'POST',
                          headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                          },
                          body: JSON.stringify({ webhook, webhook_secret: secret })
                        }).then(response => {
                          if (response.ok) {
                            response.json().then(res => {
                              if (res.ok)
                                alert("Successfully updated webhook!")
                              else
                                alert("Could not update webhook")
                            })
                          }
                          else {
                            alert("Could not update webhook")
                          }
                        }).catch(e => {
                          alert("Could not update webhook")
                        })
                      }}>Save</Button>
                    </div>
                    <hr></hr>
                    <Title level={4}>FAQ</Title>
                    <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                    </Paragraph>
                    <div style={{ maxHeight: 600, overflow: 'auto' }}>
                      <Timeline
                        style={{ padding: 10 }}
                      >
                        {faq.map((question, index) => (
                          <Timeline.Item color={"blue"} key={index}>
                            <Title level={5}>{question.question}</Title>
                            <Text>{question.answer}</Text>
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

        </Main>
      </> : <div>Loading ...</div>
  )
}


export default Api;