import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import aboutImage from '../../assets/images/about/about-image.png'
import aboutImage1 from '../../assets/images/about/about-img_1.png'
import snippet1 from '../../assets/images/banner/codeSnippet1.png'
import snippet2 from '../../assets/images/banner/codeSnippet2.png'

const UseYourNumbersLanding = () => {
    return (
        <>
            <section className="sc-about-area gray-bg sc-pt-140 sc-pb-180 sc-md-pt-80 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="image-area sc-md-mb-150">
                                    <div className="sc-about-image">
                                        <img className="about-image" src={snippet2} alt="aboutImage" />
                                        <img className="about-image_1 pulse" src={snippet1} alt="About" />
                                        <div className="circle-shape pulse"></div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-about-item sc-pl-100">
                                    <div className="sc-heading-area sc-mb-30 sc-sm-mb-15">
                                        <h2 className="heading-title">Free SMS API (Use your own numbers)</h2>

                                    </div>
                                    <div className="description sc-mb-25 sc-sm-mb-15">
                                       If you have your own pool of numbers, you can use them to setup your own sms gateway, while still being able to use all of the features of our SMS API and Bulk SMS service.
                                       This lets you skip all of  the complexities of setting up your SMS network, just follow the provided instructions.
                                       Apart from our version, which lets you send certain amount traffic for free monthly, we also have a free open source project to setup everything yourself, you can check it out <a href="https://github.com/Skocimis/opensms" target="_blank">here</a>
                                       <br/>
                                       <br/>

                                       Find out more about our hasle-free implementation <a href='https://textflow.me/useyournumbers'>here</a>
                                    </div>
                                    <div className="btn-area sc-mt-40" style={{display: 'flex', flexDirection: 'column'}}>
                                        <a className="primary-btn" href="/useyournumbers"><span>More ...</span></a>
                                        <a href="https://docs.textflow.me/send-sms-for-free" target="_blank" >View docs</a>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UseYourNumbersLanding;