import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//pages
import AdminDashboard from "../pages/admin/AdminDashboard";
import AdminLogin from "../pages/admin/AdminLogIn"
import UserCrud from "../pages/admin/crud/UserCrud";
import ApiKeyCrud from "../pages/admin/crud/ApiKeyCrud";
import RoleCrud from "../pages/admin/crud/RoleCrud";
import CountryCrud from "../pages/admin/crud/CountryCrud";
import MessageCrud from "../pages/admin/crud/MessageCrud";
import PacageCrud from "../pages/admin/crud/PacageCrud"
import PacageLevelCrud from "../pages/admin/crud/PacageLevelCrud"
import LandingPage from "../pages/landing/LandingPage"
import Navbar from "../components/Navbar";
import Register from "../pages/landing/Register";
import LogIn from "../pages/landing/LogIn";
import Contact from "../pages/landing/Contact";
import Pricing from "../pages/landing/Pricing";
import Api from "../pages/user/pages/Api"
import Home from "../pages/user/pages/Home";
import Tables from "../pages/user/pages/Tables";
import Purchases from "../pages/user/pages/Purchases";

import Rtl from "../pages/user/pages/Rtl";
import Profile from "../pages/user/pages/Profile";
import SignUp from "../pages/user/pages/SignUp";
import SignIn from "../pages/user/pages/SignIn";
import Main from "../pages/user/components/layout/Main";
import Dashboard from "../pages/user/Dashboard";

import ChangePassword from "../pages/user/pages/ChangePassword";
import RestartPassword from "../pages/user/pages/RestartPassword";
import Tos from "../pages/landing/Tos";
import LandingHome from "../pages/landing/LandingPage/views/Home";
import ReactGA from 'react-ga';
import PaymentSuccess from "../pages/user/pages/PaymentSuccess";
import NoCode from "../pages/landing/LandingPage/views/NoCode";
import Outreach from "../pages/user/pages/Outreach";
import PaymentSuccessOutreach from "../pages/user/pages/PaymentSuccessOutreach";
import PerCountryPricing from "../pages/landing/PerCountryPricing";
import Legal from "../pages/landing/Legal";

import HomeTwo from "../pages/landing/newLanding/component/Pages/HomeTwo/HomeTwo";
import Contact2 from "../pages/landing/newLanding/component/Pages/Contact/Contact2";
import Signup2 from "../pages/landing/newLanding/component/Pages/SignUp/SignUp2"
import Login2 from "../pages/landing/newLanding/component/Pages/Login/Login2";
import Service from "../pages/landing/newLanding/component/Shared/Service"
import NoCodeLanding from "../pages/landing/newLanding/component/Shared/NoCodeLanding";
import NoCodePage from "../pages/landing/newLanding/component/Pages/NoCode/NoCodeLanding";
import SmsApiLanding from "../pages/landing/newLanding/component/Pages/SmsAPI/SmsApiLanding";
import VerificationLanding from "../pages/landing/newLanding/component/Pages/Verification/VerificationLanding";
import Pricing2 from "../pages/landing/newLanding/component/Pages/Pricing/Pricing2";
import FaqLanding from "../pages/landing/newLanding/component/Pages/Faq/FaqLanding";
import Sms from "../pages/user/pages/Sms";
import UseYourNumbersDescription from "../pages/landing/newLanding/component/Pages/YourNumber/UseYourNumbersDescription";
import SmsGateway from "../pages/user/pages/SmsGateway";
import Cookies from "../pages/landing/newLanding/component/Pages/Cookies/Cookies";
import TextLinkLanding from "../pages/landing/newLanding/component/Pages/TextLink/TextLinkLanding";
export default function Router() {
  return (
    <BrowserRouter>

      <Routes>
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/legal" element={<Legal />} />

        <Route path="/" element={<HomeTwo />} />
        <Route path="/contact" element={<Contact2 />} />
        <Route path="/signup" element={<Signup2 />} />
        <Route path="/login" element={<Login2 />} />
        <Route path="/bulksms" element={<NoCodePage />}></Route>
        <Route path="/smsapi" element={<SmsApiLanding />} />
        <Route path="/smsverification" element={<VerificationLanding />} />
        <Route path="/pricing" element={<Pricing2 />} />
        <Route path="/faq" element={<FaqLanding />} />
        <Route path="/pay-as-you-go" element={<PerCountryPricing />} />
        <Route path="/useyournumbers" element={<UseYourNumbersDescription />}></Route>
        <Route path="/marketplace" element={<TextLinkLanding/>}></Route>
        
        <Route path="/dashboard" element={<Home />} />
        <Route path="/purchases" element={<Purchases />} />
        <Route path="/api" element={<Api />} />
        <Route path="/sms" element={< Sms />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-success-outreach" element={<PaymentSuccessOutreach />} />
        <Route path="/outreach" element={<Outreach />} />
        <Route path="/smsgateway" element={<SmsGateway />} />

        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/restartpassword" element={<RestartPassword />} />
        <Route path="/tos" element={<Tos />} />


      </Routes>
    </BrowserRouter>
  );
}

/*
<Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />}></Route>
        <Route path="/admin/user" element={<UserCrud />} />
        <Route path="/admin/role" element={<RoleCrud />} />
        <Route path="/admin/apikey" element={<ApiKeyCrud />} />
        <Route path="/admin/country" element={<CountryCrud />} />
        <Route path="/admin/message" element={<MessageCrud />} />
        <Route path="/admin/pacage" element={<PacageCrud />} />
        <Route path="/admin/pacageLevel" element={<PacageLevelCrud />} />
*/