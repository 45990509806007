/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  message,
  Button,
  Timeline,
  Radio,
} from "antd";
import {
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";
import ava5 from "../assets/images/logo-jira.svg";
import Main from "../components/layout/Main";
import CookieConsent from "../../../components/CookieConsent";

function Home() {
  const { Title, Text } = Typography;
  const cookies = new Cookies();
  const token = cookies.get('token');
  let navigate = useNavigate();

  const onChange = (e) => console.log(`radio checked:${e.target.value}`);
  const [loading, setLoading] = useState(true)
  const [reverse, setReverse] = useState(false);
  const [messages, setMessages] = useState([]);
  const [pacages, setPacages] = useState([]);
  const [credits, setCredits] = useState(0)

  const today = new Date();
  const dollor = [
    <svg
      width="22"
      height="22"
      style={{ display: "inline" }}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
        fill="#fff"
      ></path>
      <path
        d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
        fill="#fff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
        fill="#fff"
      ></path>
    </svg>,
  ];

  const heart = [
    <svg
      width="22"
      style={{ display: "inline" }}
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
        fill="#fff"
      ></path>
    </svg>,
  ];

  const apicloud = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline" }}
      fill="#ffffff" height="22" width="22"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 436.127 436.127">
      <path d="M350.359,191.016c-7.813-66.133-64.062-117.431-132.296-117.431c-68.234,0-124.482,51.299-132.296,117.431  C38.402,191.016,0,229.413,0,276.779c0,47.366,38.397,85.763,85.763,85.763h264.601c47.366,0,85.763-38.397,85.763-85.763  C436.127,229.413,397.725,191.016,350.359,191.016z M206.17,276.936l-21.213,21.213l-65.606-65.606l65.606-65.606l21.213,21.213  l-44.394,44.394L206.17,276.936z M251.17,298.149l-21.213-21.213l44.394-44.394l-44.394-44.394l21.213-21.213l65.606,65.606  L251.17,298.149z" />
    </svg>,
  ];

  const outreach = [
    <svg xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline" }}

      fill="#ffffff"
      id="Layer_1"
      height="22"
      viewBox="0 0 24 24"
      width="22">
      <path d="m1.717 14.7 4.133 9.3h2.456a2.752 2.752 0 0 0 2.514-3.868l-2.281-5.132h2.461a5.006 5.006 0 0 1 5 5h2v-20h-2a5.006 5.006 0 0 1 -5 5h-8a3 3 0 0 0 -3 3v4a3 3 0 0 0 1.717 2.7zm7.275 6.243a.751.751 0 0 1 -.686 1.057h-1.156l-3.111-7h2.311zm-6.992-12.943a1 1 0 0 1 1-1h8a6.973 6.973 0 0 0 5-2.111v10.222a6.973 6.973 0 0 0 -5-2.111h-8a1 1 0 0 1 -1-1zm20.293 7.707-2.288-2.288 1.414-1.414 2.288 2.288zm-.836-7.75-1.414-1.414 2.25-2.25 1.414 1.414zm-.457 1.043h3v2h-3z" /></svg>
  ]
  const count = [
    {
      today: "Messages sent today",
      title: messages.reduce((acc, msg) => (new Date(msg.createdAt) >= new Date()) ? acc += 1 : acc += 0, 0),
      icon: heart,
      bnb: "bnb2",
    },
    {
      today: "Today’s spending",
      title: messages.reduce((acc, msg) => (new Date(msg.createdAt) >= new Date()) ? acc += msg.price : acc += 0, 0),
      persent: "$",
      icon: dollor,
      bnb: "bnb2",
    },
    {
      today: "Click to access no-code outreach tool",
      title: "Outreach",
      icon: outreach,
      bnb: "bnb2",
    },
    {
      today: "Click to send messags from console",
      icon: apicloud,
      title: "API",
    },
  ];





  useEffect(() => {
    if (!token || token.length == 0) {
      navigate('/signup')
      return
    }
    fetch(global.SERVIP + 'user/get-credits', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.ok) {
          setCredits(response.credits);
        }
      })
      .catch(_ => { })
    async function requestMessages() {
      /*if (global.messages) {
        setMessages(global.messages);
        if (loading)
          setLoading(false);
        return;
      }*/
      await fetch(global.SERVIP + 'user/messages2', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              setMessages(res);
              if (loading)
                setLoading(false);
            })
          }
          else {
            alert('Error M')
          }
        })
        .catch(e => {
          console.log("NEEE");
        })
    }
    async function requestPacages() {
      if (global.pacages) {
        setPacages(global.pacages);
        if (loading)
          setLoading(false);
        return;
      }
      global.pacages = [];
      await fetch(global.SERVIP + 'user/pacages', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
        .then(response => {
          if (response.ok) {
            response.json().then(res => {
              global.pacages = res;
              setPacages(global.pacages)
              if (loading)
                setLoading(false);
            })
          }
          else {
            alert('Error P')
            cookies.remove('token');
            window.location.reload();
          }
        })
    }
    requestMessages();
    requestPacages();
  }, [loading]);
  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    (token && token.length > 0 && !loading) ?
      <>
        <Main>
          <CookieConsent></CookieConsent>
          <div className="layout-content">
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              {count.map((c, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mb-24"
                >
                  <Card
                    onClick={() => {
                      if (c.title == "API") {
                        navigate('/api')
                      }
                      if (c.title == "Outreach") {
                        navigate('/outreach')
                      }
                    }}
                    bordered={false} className="criclebox ">
                    <div className="number">
                      <Row align="middle" gutter={[24, 0]}>
                        <Col xs={18}>
                          <span>{c.today}</span>
                          <Title level={3}>
                            {c.title} <small className={c.bnb}>{c.persent}</small>
                          </Title>
                        </Col>
                        <Col xs={6}>
                          <div className="icon-box">{c.icon}</div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <Echart messages={messages} />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <LineChart messages={messages} />
                </Card>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
                <Card bordered={false} className="criclebox cardbody h-full">
                  <div className="project-ant">
                    <div>
                      <Title level={5}>Credits</Title>
                      <Paragraph className="lastweek">
                        Your credit balance
                      </Paragraph>
                      <p style={{ fontSize: 20 }}>You have ${credits} in credits</p>

                      <Title level={5}>Packages</Title>
                      <Paragraph className="lastweek">
                        Your current packages and their associated balances
                      </Paragraph>
                    </div>
                    <div className="ant-filtertabs">
                      <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                        <Radio.Group onChange={onChange} defaultValue="a">
                          <Radio.Button value="a" onClick={() => navigate('/purchases')}>Purchase credits</Radio.Button>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <div className="ant-list-box table-responsive">
                    <div style={{ maxHeight: 300, overflow: 'auto' }}>

                      <table className="width-100">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Countries</th>
                            <th>Price per message</th>
                            <th>Messages left</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pacages.map((pacage, index) => (
                            <tr key={index}>
                              <td style={{ textAlign: "center" }}>
                                <h6>
                                  <img
                                    src={ava5}
                                    alt={""}
                                    className="avatar-sm"
                                    style={{ display: "inline" }}
                                  /><br></br>{pacage.id}
                                </h6>
                              </td>
                              <td>
                                <span className="text-xs font-weight-bold">
                                  {pacage.countries.reduce((acc, country) => acc += country.name + " ", "")}
                                </span>

                              </td>
                              <td>
                                <span className="text-xs font-weight-bold">
                                  {pacage.pricePerMessageUSD} $
                                </span>
                              </td>
                              <td>
                                <div className="percent-progress">
                                  <span className="text-xs font-weight-bold">
                                    {pacage.messagesLeft}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="uploadfile shadow-none">

                    <Button
                      className="ant-full-box"
                      onClick={() => navigate('/purchases')}
                    >
                      <span className="click">View available packages</span>
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <div className="timeline-box">
                    <Title level={5}>Message History</Title>
                    <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                    </Paragraph>
                    <div style={{ maxHeight: 300, overflow: 'auto' }}>

                      <Timeline
                        pending="Recording..."
                        className="timelinelist"
                        style={{ padding: 10 }}
                        reverse={reverse}
                      >
                        {messages.map((message, index) => (
                          <Timeline.Item color={"blue"} key={index}>
                            <Title level={5}>To : {message.to + " " + message.country}</Title>
                            <Text>{new Date(message.createdAt).toDateString()}</Text>
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                    <Button
                      type="primary"
                      className="width-100"
                      onClick={() => setReverse(!reverse)}
                    >
                      {<MenuUnfoldOutlined />} REVERSE
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>


          </div>
        </Main>
      </>
      : <div>Loading ...</div>
  );
}

export default Home;
