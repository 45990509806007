/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import eChart from "./configs/eChart";
import { useEffect, useState } from "react";

function EChart(props) {
  const { Title, Paragraph } = Typography;
  const [messagesPerMonth, setMessagesPerMonth] = useState([])
  const [totalSpending, setTotalSpending] = useState(0)
  const [totalMessages, setTotalMessages] = useState(0)
  const [averateCostPerMessage, setAverageCostPerMessage] = useState(0);

  useEffect(() => {
    const result = Array(12).fill(0);
    props.messages.forEach(obj => {
      const month = new Date(obj.createdAt).getMonth();
      result[month] += obj.price;
    });
    for (let i = 0; i < result.length; i++) {
      if (result[i])
        result[i] = parseFloat(result[i].toFixed(3));
    }
    let stop = true;
    for (let i = 0; i < result.length; i++) {
      if (result[i] != messagesPerMonth[i]) {
        stop = false;
      }
    }
    if (stop) return;
    setMessagesPerMonth(result);
    const total = result.reduce((acc, item) => acc += item, 0)
    setTotalSpending(total)
    setTotalMessages(props.messages.length)
    setAverageCostPerMessage(total / props.messages.length)
  })
  let items = [
    {
      Title: totalSpending.toFixed(1) + "$",
      user: "Total spending for the past 12 months",
    },
    {
      Title: totalMessages,
      user: "Messages",
    },
    {
      Title: (averateCostPerMessage || 0).toFixed(2) + " $",
      user: "Average cost per message",
    },

  ];
  //get all messages 
  return (
    <>
      <div id="chart">
        <ReactApexChart
          className="bar-chart"
          options={eChart.options}
          series={[{
            name: "Amount",
            data: messagesPerMonth,
            color: "#fff",
          }]}
          type="bar"
          height={220}
        />
      </div>
      <div className="chart-vistior">
        <Title level={5}>Spending</Title>
        <Paragraph className="lastweek">
          This chart shows you spending on messages on TextFlow for the past year
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default EChart;
