import logo from './logo.svg';
import './App.css';
import Router from './router/Router'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../src/pages/landing/newLanding/assets/fonts/remixicon.css';
import '../src/pages/landing/newLanding/assets/css/style.css';
//import "antd/dist/antd.css";
import Cookies from 'universal-cookie'; // Import Cookies

const ip_map = {
  production: "https://textflow.me/api/",
  spremo_test: "http://localhost/api/",
  viktor_test: "http://192.168.1.2:80/api/",
  remote_test: "http://109.111.248.85:80/api/",
}

global.SERVIP = ip_map.production;
global.bannerClosed = false
window.purchaseAmount=0;

function App() {
  // Initialize cookies
  const cookies = new Cookies();

  // useEffect to set show_banner cookie
  useEffect(() => {
    console.log(cookies.get('show_banner'))
    // Check if the show_banner cookie exists
    const showBanner = cookies.get('show_banner');
    if (!showBanner) {
      // Set show_banner cookie to true if it doesn't exist
      cookies.set('show_banner', true, { path: '/', expires: new Date(Date.now() + 365 * 86400 * 1000) });
    }
  }, []); // Empty array means this effect runs only once after the initial render

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
