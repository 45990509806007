import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactPixel from 'react-facebook-pixel';

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");

  if (parts.length == 2) {
    return parts.pop().split(";").shift();
  } else if (value.startsWith(name + "=")) {
    return value.split(";")[0].substring(name.length + 1);
  }
}

if (getCookie('targeting_cookies') === 'true') {
  const options = {
    autoConfig: true,
    debug: false,
  };

  ReactPixel.init('1418692145367135', null, options);
  ReactPixel.pageView(); // For tracking page view
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
