/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logotext.png";
import Cookies from 'universal-cookie';
import megaphone from '../../../../components/assets/images/megaphone.svg';

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const cookies = new Cookies();

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const billing = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const rtl = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const api = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline" }}
      fill="#ffffff" height="22" width="22"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 436.127 436.127">
      <path d="M350.359,191.016c-7.813-66.133-64.062-117.431-132.296-117.431c-68.234,0-124.482,51.299-132.296,117.431  C38.402,191.016,0,229.413,0,276.779c0,47.366,38.397,85.763,85.763,85.763h264.601c47.366,0,85.763-38.397,85.763-85.763  C436.127,229.413,397.725,191.016,350.359,191.016z M206.17,276.936l-21.213,21.213l-65.606-65.606l65.606-65.606l21.213,21.213  l-44.394,44.394L206.17,276.936z M251.17,298.149l-21.213-21.213l44.394-44.394l-44.394-44.394l21.213-21.213l65.606,65.606  L251.17,298.149z" />
    </svg>,
  ]

  const sms = [
    <svg fill="#000000" height="18" width="18" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 458 458">
      <g>
        <g>
          <path d="M428,41.534H30c-16.569,0-30,13.431-30,30v252c0,16.568,13.432,30,30,30h132.1l43.942,52.243
			c5.7,6.777,14.103,10.69,22.959,10.69c8.856,0,17.258-3.912,22.959-10.69l43.942-52.243H428c16.568,0,30-13.432,30-30v-252
			C458,54.965,444.568,41.534,428,41.534z M323.916,281.534H82.854c-8.284,0-15-6.716-15-15s6.716-15,15-15h241.062
			c8.284,0,15,6.716,15,15S332.2,281.534,323.916,281.534z M67.854,198.755c0-8.284,6.716-15,15-15h185.103c8.284,0,15,6.716,15,15
			s-6.716,15-15,15H82.854C74.57,213.755,67.854,207.039,67.854,198.755z M375.146,145.974H82.854c-8.284,0-15-6.716-15-15
			s6.716-15,15-15h292.291c8.284,0,15,6.716,15,15C390.146,139.258,383.43,145.974,375.146,145.974z"/>
        </g>
      </g>
    </svg>
  ]


  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signup = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      key={0}
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>,
  ];
 const phone = [
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="15"
  height="15"
  version="1.1"
  viewBox="0 0 202.592 202.592"
  xmlSpace="preserve"
>
  <path
    fill="#010002"
    d="M198.048 160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001.433L128 145.009a649.985 649.985 0 01-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599 101.48 65.03 87.834 59.5 77.874c-.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565 5.2-5.207c6.46-6.46 6.639-16.778.419-23.001L42.715 4.769c-6.216-6.216-16.541-6.027-23.001.433l-8.818 8.868.243.24c-2.956 3.772-5.429 8.124-7.265 12.816-1.696 4.466-2.752 8.729-3.235 12.998-4.13 34.25 11.52 65.55 53.994 108.028 58.711 58.707 106.027 54.273 108.067 54.055 4.449-.53 8.707-1.593 13.038-3.275 4.652-1.818 9.001-4.284 12.769-7.233l.193.168 8.933-8.747c6.446-6.459 6.632-16.777.415-23.015zm-7.365 16.059l-3.937 3.93-1.568 1.507c-2.469 2.387-6.743 5.74-12.984 8.181-3.543 1.364-7.036 2.24-10.59 2.663-.447.043-44.95 3.84-100.029-51.235C14.743 94.38 7.238 67.395 10.384 41.259c.394-3.464 1.263-6.95 2.652-10.593 2.462-6.277 5.812-10.547 8.181-13.02l5.443-5.497c2.623-2.63 6.714-2.831 9.112-.433l31.286 31.286c2.394 2.401 2.205 6.492-.422 9.13L45.507 73.24l1.95 3.282c1.084 1.829 2.23 3.879 3.454 6.106 5.812 10.482 13.764 24.83 29.121 40.173 15.317 15.325 29.644 23.27 40.094 29.067 2.258 1.249 4.32 2.398 6.17 3.5l3.289 1.95 21.115-21.122c2.634-2.623 6.739-2.817 9.137-.426l31.272 31.279c2.391 2.397 2.201 6.488-.426 9.115z"
  ></path>
</svg>
 ]
  const outreach = [
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" height="14" viewBox="0 0 24 24" width="14" data-name="Layer 1"><path d="m1.717 14.7 4.133 9.3h2.456a2.752 2.752 0 0 0 2.514-3.868l-2.281-5.132h2.461a5.006 5.006 0 0 1 5 5h2v-20h-2a5.006 5.006 0 0 1 -5 5h-8a3 3 0 0 0 -3 3v4a3 3 0 0 0 1.717 2.7zm7.275 6.243a.751.751 0 0 1 -.686 1.057h-1.156l-3.111-7h2.311zm-6.992-12.943a1 1 0 0 1 1-1h8a6.973 6.973 0 0 0 5-2.111v10.222a6.973 6.973 0 0 0 -5-2.111h-8a1 1 0 0 1 -1-1zm20.293 7.707-2.288-2.288 1.414-1.414 2.288 2.288zm-.836-7.75-1.414-1.414 2.25-2.25 1.414 1.414zm-.457 1.043h3v2h-3z" /></svg>
  ]

  return (
    <>
      <div className="brand" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo} style={{ width: "100%", tintColor: "white", borderRadius: 5, objectPosition: "center", objectFit: "contain" }} alt="" />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="label">Account overview</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/purchases">
            <span
              className="icon"
              style={{
                background: page === "purchases" ? color : "",
              }}
            >
              {billing}
            </span>
            <span className="label">Purchases and Pricing</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/api">
            <span
              className="icon"
              style={{
                background: page === "API" ? color : "",
              }}
            >
              {api}
            </span>
            <span className="label">API</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/sms">
            <span
              className="icon"
              style={{
                background: page === "SMS" ? color : "",
              }}
            >
              {sms}
            </span>
            <span className="label">Your messages</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/outreach">
            <span
              className="icon"
              style={{
                background: page === "outreach" ? color : "",
              }}
            >
              {outreach}
            </span>
            <span className="label">No-code outreach</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/smsgateway">
            <span
              className="icon"
              style={{
                background: page === "smsgateway" ? color : "",
              }}
            >
{phone}
            </span>
            <span className="label">Use your numbers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/changepassword">
            <span
              className="icon"
              style={{
                background: page === "changepassword" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="label">Change password</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink onClick={() => { cookies.remove('token') }} to="/">
            <span className="icon">{signin}</span>
            <span className="label">Sign out</span>
          </NavLink>
        </Menu.Item>

      </Menu>
      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            {dashboard}
          </span>
          <h6>Need help, or just want to see examples of the API in use?</h6>
          <p>Please check our docs / github repo</p>
          <Button type="primary"
            style={{ width: '100%', color: 'gray' }}>
            <a style={{ color: "black" }} href="https://docs.textflow.me">DOCUMENTATION</a>
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
