// AnnouncementBanner.js
import React from 'react';
import './Banner.css'
const Banner = ({ message, onClose, onMoreInfo }) => {
    return (
        <div className="announcement-banner">
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <p>{message}</p>
                <button className="close-button" onClick={onClose}>✕</button>
            </div>
            <div>
                <button className="btn1" onClick={onMoreInfo}><i className="animation"></i>I'm interested<i className="animation"></i></button>
            </div>
        </div>
    );
}


export default Banner;
