import React from 'react';
import Header from '../../Shared/Header';
import logo2 from '../../../assets/images/logo2.png'
import Footer from '../../Shared/Footer';
import NoCodeService from './NoCodeService';
import CookieConsent from '../../../../../../components/CookieConsent';
const SmsApiLanding = () => {
    return (
        <>
            <CookieConsent></CookieConsent>
            <Header
                parentMenu='home'
                headerNormalLogo={logo2}
                headerStickyLogo={logo2}
                headerClass='sc-header-section sc-header-section2'
                signUpBtn='secondary-btn'
            />
            <div style={{ height: '10vh' }}></div>
            <NoCodeService />
            <Footer />
        </>
    );
};

export default SmsApiLanding;