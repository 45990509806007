import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const MenuItems = (props) => {
    const { parentMenu } = props;
    const location = useLocation();

    return (
        <>
            <li>
                <Link className={parentMenu === 'home' ? 'menu-item-has-children active' : 'menu-item-has-children'} to="/">Home</Link>
        
            </li>
            <li className="menu-item-has-children">
                <Link className={parentMenu === 'pages' ? 'menu-item-has-children active' : 'menu-item-has-children'} to="#">Services</Link>
                <ul className="sub-menu-list">
                    <li><Link className={location.pathname === '/smsapi' ? 'active' : ''} to="/smsapi">SMS API</Link></li>
                    <li><Link className={location.pathname === '/smsverification' ? 'active' : ''} to="/smsverification">Verification API</Link></li>
                    <li><Link className={location.pathname === '/bulksms' ? 'active' : ''} to="/bulksms">No-code outreach</Link></li>
                    <li><Link className={location.pathname === '/useyournumbers' ? 'active' : ''} to="/useyournumbers">Use your own numbers</Link></li>
                </ul>
            </li>
            <li>
            <Link className={location.pathname === '/marketplace' ? 'active' : ''} to="/marketplace">SMS Marketplace</Link>

            </li>
            <li><Link className={location.pathname === '/contact' ? 'active' : ''} to="/contact">Contact</Link></li>
            <li>
                <a target="_blank" className={location.pathname === '/about' ? 'active' : ''} href="https://docs.textflow.me/">Docs</a>
            </li>
            <li>
                <a className={location.pathname === '/signup' ? 'active' : ''} href="/signup">Sign up</a>
            </li>
        </>
    );
}

export default MenuItems;