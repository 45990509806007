import React from "react";
import Paragraph from "antd/lib/typography/Paragraph";

const content =  `
TextFlow Paid SMS API Service Terms of Service

Service Description:
TextFlow Paid SMS API Service allows users to send SMS messages through our API.

Payment Terms:
The first two messages are free of charge, after that users must pay for the SMS messages they send through our API by purchasing a bulk SMS package.

Refund Policy:
Payments made for SMS packages are refundable. We will issue a refund for the messages you have not used from your packages, considering that the terms of service have not been broken. You can request a refund within 3 days of purchase. 
If you have not broken the terms of service, we can change your TextFlow subscription / package / credits for another item of the same value within 7 days of purchase. 
Contact support@textflow.me for help.

Use of Service:
The TextFlow Paid SMS API Service may only be used for lawful purposes and in accordance with the terms of this agreement. 
Prohibited uses include, but are not limited to, sending spam or other unsolicited messages, sending illegal or harmful content, or using the service to harass or abuse others. Users may only send promotional messages to recipients who have given their explicit consent.

Limitation of Liability:
We shall not be liable for any damages or losses incurred as a result of the use of our TextFlow Paid SMS API Service.

Termination of Service:
We reserve the right to terminate the TextFlow Paid SMS API Service or any user's access to the service at any time and without notice, in the event of a violation of these terms or for any other reason.

Changes to Terms of Service:
We reserve the right to modify these terms at any time, and such changes will be effective immediately upon posting. Your continued use of the TextFlow Paid SMS API Service constitutes your acceptance of the revised terms. We may change these terms at our discretion.

Governing Law:
This agreement shall be governed by and construed in accordance with the laws of the country in which the service is provided.

By using our TextFlow Paid SMS API Service, you agree to be bound by these terms and conditions.`
function Tos(){
    return(
        <div>
            <h1 style={{marginTop:20}}>Terms of Service</h1>
            <div style={{whiteSpace: 'pre-wrap',padding:'10vw'}}>
            {content}
            </div>
        </div>
    )
}

export default Tos;