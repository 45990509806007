import React from 'react';

const FaqQuestion = () => {
    return (
        <>
            <div className="sc-faq-pages-area sc-pt-70 sc-md-pt-10 sc-pb-10 sc-md-pb-10" > 
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3" >
                            <div className="sc-heading text-center sc-mb-60" style={{marginTop:"25%"}}>
                                <h2 className="sc-mb-20">Frequently Asked Questions</h2>
                             
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                            <div className="accordion sc-faq-style" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Do i need to put prefix on all phone numbers (+1 for example for U.S)?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >Yes, you need to put international prefix based on the country. For example : +12542145739 for sending a SMS to the US</strong>
                                        </div>
                                    </div>
                                </div>
                        
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour">
                                            How to send bulk sms online?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >Just use our no code bulk sms tool, you just need a list of phone numbers.</strong>
                                            </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            What messages can i send?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >You can send notifications, links, promotions, ... As long as these messages are complient with out Tearms of Service.</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree">
                                            Do you offer special pricing for volumes?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >Yes, if you don't see what you're looking for in our offerings, contact us at sales@textflow.me</strong>

                                        </div>
                                    </div>
                                </div>
                              
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive">
                                            What is the limit for SMS per second?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong
                                            >The limit for using our API is 1 sms per second, and if you are using your own long-code numbers (regular phone numbers), the limit is one message every 6.5 secondes. For the second option, we have safty option in our terminal, so that your numbers don't get blacklisted by carriers.</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqQuestion;