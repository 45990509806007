import React from 'react'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import clientImg from '../../../assets/images/icons/client-img.png'
import clientImg1 from '../../../assets/images/icons/client_img1.png'
import clientImg3 from '../../../assets/images/icons/client_img.png'
import clientImg2 from '../../../assets/images/icons/client_img2.png'

const ClientTestimonial = () => {

    const CustomRightArrow = ({ onClick }) => {
        return <button className='commonArrow arrowRight' style={{display:'flex', justifyContent: 'center', alignItems: 'center',width:50,height:50}}  onClick={() => onClick()}><i className="ri-arrow-right-line"></i></button>;
    };

    const CustomLeftArrow = ({ onClick }) => {
        return <button className='commonArrow arrowLeft' style={{display:'flex', justifyContent: 'center', alignItems: 'center',width:50,height:50}} onClick={() => onClick()}><i className="ri-arrow-left-line"></i></button>;
    };

    return (
        <>
            <section className="sc-client-section-area yellow-bg sc-pt-125 sc-md-pt-65 sc-pb-140 sc-md-pb-80">
                <div className="container">
                    <div className="client-testimonial-wrap">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="sc-heading-area sc-mb-55 sc-md-mb-45 text-center">
                                    <h2 className="heading-title sc-mb-25">Are we good ?</h2>
                                    <h4>Well ...</h4>
                                </div>
                            </div>
                        </div>

                        <div className='client-carousel-wrapper'>
                            <Carousel className="client-carousel"
                                infinite={true}
                                draggable={true}
                                arrows={true}
                                pauseOnHover={true}
                                slidesToSlide={1}
                                swipeable={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                customRightArrow={<CustomRightArrow />}
                                customLeftArrow={<CustomLeftArrow />}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 992
                                        },
                                        items: 3,
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 991,
                                            min: 577
                                        },
                                        items: 2,
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 576,
                                            min: 1
                                        },
                                        items: 1,
                                    }
                                }}>
                                <div className="sc-client-item">
                                   
                                   
                                    <div className="description sc-mb-15">
                                    Textflow's seamless integration into our workflow saved us time and resources. The platform's security features give us peace of mind.</div>
                                  
                                    <div className="sc-client-author d-flex align-items-center">
                                     
                                        <div className="author-text">
                                            <h5 className="client-title sc-mb-0">Jackson Mitchell</h5>
                                            <span>Director of Marketing at ClearVoice Inc.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-client-item">
                                   
                                   
                                    <div className="description sc-mb-15">
                                    Textflow has revolutionized our SMS marketing! Scalable, cost-effective, and secure. A must-have tool for businesses! 5/5 stars.</div>
                                   
                                    <div className="sc-client-author d-flex align-items-center">
                               
                                        <div className="author-text">
                                            <h5 className="client-title sc-mb-0">Emily Thompson</h5>
                                            <span> Communications Manager at Acme Softworks LLC</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-client-item">
                                   
                                   
                                    <div className="description sc-mb-15">
                                    Amazing experience with Textflow! The flexibility and insightful analytics helped us optimize our campaigns and boost conversions. Highly recommend!" 📈
                                    </div>
                                  
                                    <div className="sc-client-author d-flex align-items-center">
                                      
                                        <div className="author-text">
                                            <h5 className="client-title sc-mb-0">Ethan Tremblay</h5>
                                            <span>Digital Marketing Specialist at PineLeaf Technologies Corp.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-client-item">
                                    
                                    
                                    <div className="description sc-mb-15">
                                    Textflow's no-code SMS outreach made our lives easier. Affordable and efficient, we've seen a significant increase in customer engagement. Bravo!" 👏
                                    </div>
                                 
                                    <div className="sc-client-author d-flex align-items-center">
                                      
                                        <div className="author-text">
                                            <h5 className="client-title sc-mb-0"> Isabella Davies</h5>
                                            <span>Product Manager at RedFox Solutions Ltd.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-client-item">
                                    
                                    
                                    <div className="description sc-mb-15">
                                    "Superb customer support, easy-to-use dashboard, and flexible pricing. Textflow is the complete SMS API package. Our ROI has never been better
                                    </div>
                               
                                    <div className="sc-client-author d-flex align-items-center">
                                        
                                        <div className="author-text">
                                            <h5 className="client-title sc-mb-0"> Aarav Sharma</h5>
                                            <span>Lead Developer at SkyTech Innovations Pvt. Ltd.</span>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ClientTestimonial;